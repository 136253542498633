import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { user_events } from './../../models';

@Component({
  selector: 'dcgrs-calander',
  templateUrl: './calander.component.html',
  styleUrls: ['./calander.component.scss'],
})
export class CalanderComponent {
  @Input() dateMarked: user_events[];
  @Output() dateChange = new EventEmitter();
  counter: number;
  constructor(private dateAdapter: DateAdapter<Date>) {}

  ngOnInit() {
    this.dateAdapter.setLocale('en-US');
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.dateMarked
        .map((strDate: any) => new Date(strDate.date))
        .some(
          (d: any) =>
            d.getDate() === date.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        );

      return highlightDate ? 'special-date' : '';
    };
  }

  valueChanged(event:any){
    this.dateChange.emit(event);
  }
}
