<div [ngClass]="profile ? 'profilePage' : 'profile-picture'">
  <ng-container *ngIf="profilePictureUrl; else initialsTemplate">
    <img [src]="profilePictureUrl" alt="Profile Picture" />
  </ng-container>
  <ng-template #initialsTemplate>
    <div
      class="initials"
      [style.backgroundColor]="profileDetails.profile.color"
      *ngIf="profile && profileDetails"
    >
      {{ profileInitials }}
    </div>
    <div
      class="initials"
      [style.backgroundColor]="profileDetails.profile.color"
      *ngIf="!profile && profileDetails"
    >
      {{ profileInitials }}
    </div>
  </ng-template>
</div>
