<div class="main-dailog-container">
  <mat-icon style="float: right; cursor: pointer" (click)="onNoClick()"
    >close</mat-icon
  >
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    [ngStyle]="{ width: dailogWidth, height: dailogHeight }"
  >
    <div>
      <img
        src="./../../../assets/alertIcons/celabrate.png"
        [ngStyle]="{ width: iconWidth, height: iconHeight }"
      />
    </div>
    <div [ngStyle]="{ width: dailogWidth }" style="text-align: center">
      {{ data.message }}
    </div>
  </div>
</div>
