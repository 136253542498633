<div class="main-container">
  <mat-icon style="float: right" class="closeIcon" matTooltip="close" [mat-dialog-close]
    >close</mat-icon
  >
  <!-- <h3 mat-dialog-title>ResetPassword</h3> -->
  <div mat-dialog-content>
    <span *ngIf="data.component == 'profile'"
      >Would you like to Reset Password?</span
    ><br />
    <span *ngIf="data.component == 'sign_in'"
      >Enter your email address to receive a password reset link.</span
    >
    <br />
    <span *ngIf="data.component == 'profile'">
      After confirming 'OK' the reset password will be sent to your email,
      please check it and proceed further. Thank you..!
    </span>
    <span *ngIf="data.component == 'sign_in'">
      Click 'Yes' after entering your email, and the reset instructions will be
      sent to your inbox.
    </span>
    <div *ngIf="data.component == 'sign_in'" style="margin-top: 2%">
      <mat-label>Email</mat-label>
      <div>
        <mat-form-field appearance="outline">
          <input matInput [(ngModel)]="email" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      cdkFocusInitial
      *ngIf="data.component == 'profile'"
      (click)="send()"
    >
      yes
    </button>
    <button
      mat-button
      cdkFocusInitial
      *ngIf="data.component == 'sign_in'"
      (click)="attachEmailAndSend()"
    >
      yes
    </button>
    <button mat-button [mat-dialog-close]="false">No</button>
  </div>
</div>
<!-- <h1 mat-dialog-title>ResetPassword</h1>
<div mat-dialog-content>
  Would you like to Reset Password?<br />
  <br />
  <form [formGroup]="emailForm" class="loginForm">
    <mat-label>Email</mat-label>
    <div>
      <mat-form-field appearance="outline">
        <input matInput formControlName="email" />
      </mat-form-field>
    </div>
    <div style="float: right; cursor: pointer">Reset Password</div>
  </form>
  After confirming 'OK' the reset password will be sent to your email, please
  check it and proceed further. Thank you..!
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">No</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</div> -->
