import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysAgo'
})
export class DaysAgoPipe implements PipeTransform {

  transform(value: Date | string): string {
    if (!value) return '';

    const currentDate = new Date();
    const pastDate = new Date(Number(value));
    const diffTime = Math.abs(currentDate.getTime() - pastDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 1) {
      return 'Today';
    } else if (diffDays === 2) {
      return 'Yesterday';
    } else {
      return `${diffDays} days ago`;
    }
  }
}
