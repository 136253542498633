import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { AuthService } from 'src/_providers/_http/auth.service';
import { JobsService } from 'src/_providers/_http/jobs.service';

@Component({
  selector: 'dcps-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss'],
})
export class AlertNotificationComponent {
  snackBarClass: string;
  private audio: HTMLAudioElement;
  constructor(
    public snackBarRef: MatSnackBarRef<AlertNotificationComponent>,
    private _route: Router,
    private _auth: AuthService,
    private _jobs: JobsService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.audio = new Audio();
    this.audio.src =
      './../../../assets/audio/mixkit-software-interface-remove-2576.wav';
    this.audio.load();

    this.audio.addEventListener('error', (e) => {});

    this.audio.addEventListener('canplaythrough', () => {});
  }

  ngOnInit(): void {
    this.getDetails();
  }

  getDetails() {
    let profileId: string | undefined;
    if (this.data.message.name === 'JobAssignmentToRecruiter') {
      profileId = this.data.message.params.recruitmentManagerId;
    } else if (this.data.message.name === 'JobAssignmentToRecruitmentManager') {
      profileId = this.data.message.params.salesManagerId;
    } else if (this.data.message.name === 'CandidateAddedToJob') {
      profileId = this.data.message.params.recruiterId;
    }

    if (profileId) {
      this._auth
        .getProfile(profileId)
        .pipe(
          switchMap((profileRes: any) => {
            const profileDetails = profileRes?.data?.profileDetails?.profile;
            if (profileDetails) {
              Object.assign(this.data.message, {
                profile: profileDetails,
              });
              console.log('Playing audio');
              this.audio
                .play()
                .catch((err) => console.error('Playback failed:', err));
            } else {
              console.error('Profile data is missing or incomplete');
            }

            return [];
          })
        )
        .subscribe({
          error: (err: any) => {
            console.error('Error occurred:', err);
          },
        });
    }
  }

  openAction() {
    if (this.data.message.name == 'JobAssignmentToRecruiter') {
      this._route.navigate(['/jobs/' + this.data.message.params.jobId]);
    } else if (this.data.message.name == 'JobAssignmentToRecruitmentManager') {
      this._route.navigate(['/job_details/' + this.data.message.params.jobId]);
    } else if (this.data.message.name == 'CandidateAddedToJob') {
      this._route.navigate([
        '/managerApproval/' +
          this.data.message.params.candidateId +
          '/' +
          this.data.message.params.recruiterId +
          '/' +
          this.data.message.params.jobId+'/applied',
      ]);
    }
    this.snackBarRef.dismiss();
  }

  hasPicKey(obj: any): boolean {
    return obj != null && typeof obj === 'object' && 'pic' in obj;
  }

  prepareBg(base64: string) {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${`data:image/jpeg;base64,` + base64})`
    );
  }

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
