<div class="main-container">
  <div>Case Summary</div>
  <table>
    <tr>
      <td>Client :</td>
      <td>ANZ Client</td>
    </tr>
    <tr>
      <td>Client Contact :</td>
      <td>Soumya George</td>
    </tr>
    <tr>
      <td>Job Type :</td>
      <td>Contract</td>
    </tr>
    <tr>
      <td>Budget :</td>
      <td></td>
    </tr>
    <tr>
      <td>Priority :</td>
      <td>Important</td>
    </tr>
    <tr>
      <td>Number of Roles :</td>
      <td>1</td>
    </tr>
    <tr>
      <td>Sourcing :</td>
      <td>Confirmed</td>
    </tr>
  </table>
  <mat-divider style="margin-top: 2%"></mat-divider>
  <mat-list>
    <mat-list-item>Creator</mat-list-item>
    <mat-list-item
      ><mat-icon style="color: #02c94f; font-size: 20px">check_circle</mat-icon
      >Mira DSilva</mat-list-item
    >
  </mat-list>
  <mat-divider style="margin-top: 2%"></mat-divider>
  <mat-list-item></mat-list-item>
</div>
