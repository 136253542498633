import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

fetch('/assets/config.json')
  .then((response) => response.json())
  .then((config) => {
    environment.ApiBaseUrl = `https://gateway.${config.apiUrl}/api`;
    environment.WebSocketUrl = `wss://api.${config.apiUrl}`
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch((err) => console.error('Failed to load config:', err));

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if ('serviceWorker' in navigator && environment.production) {
  navigator.serviceWorker.register('/custom-sw.js').then((registration) => {
    console.log(
      'Custom Service Worker registered with scope:',
      registration.scope
    );
  });
}
