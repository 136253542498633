import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/_providers/_http/auth.service';
import { CandidateService } from 'src/_providers/_http/candidate.service';
import { JobsService } from 'src/_providers/_http/jobs.service';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';
import { SharedService } from 'src/_providers/_http/shared.service';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';

@Component({
  selector: 'app-candidate-form',
  templateUrl: './candidate-form.component.html',
  styleUrls: ['./candidate-form.component.scss']
})
export class CandidateFormComponent {
  candidateForm: FormGroup;
  noticePeriods: string[] = ['15', '30', '45', '60', '75', '90'];
  userId: any;
  updatedForm: any;
  candidateIdForResume: number;
  latestResumeDetails: any;
  candidateDetails: any;
  showRawResume: boolean;
  showCandidateForm: boolean;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private _candidate: CandidateService,
    private _jobs: JobsService,
    private _snakbar: SnackbarService,
    private _ls_service: LocalStorageService,
    private _auth: AuthService,
    private datePipe: DatePipe,
    private jobDataService: SharedService,
  ) {

  }

  ngOnInit(): void {
    this.userId = this._ls_service.getItem('loggedInUser').userId;
    this.candidateForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      primarySkill: ['', Validators.required],
      currentCTC: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      noticePeriod: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      expectedCTC: ['', Validators.required],
      dob: ['', Validators.required],
      workLocation: ['', Validators.required],
      gender: ['', Validators.required],
      visaStatus: ['', Validators.required],
      jobId: ['', Validators.required],
      relocationStatus: ['', Validators.required],
      currency: ['', Validators.required],
      currencyCTC: ['', Validators.required],
      
    });
    this.jobDataService.candidateIdForm$.subscribe((res) => {
      this.candidateDetails =  res;
      this.candidateIdForResume = this.candidateDetails.candidateId;
      this.CandidateDetails()
    })
  }

  CandidateDetails() {
    this._candidate.getCandidateDetails(this.candidateIdForResume).subscribe({
      next: (res: any) => {
        this.latestResumeDetails = res.data.candidateData;
        this.candidateForm.patchValue({
          firstName: this.latestResumeDetails.firstName || '',
          lastName: this.latestResumeDetails.lastName || '',
          primarySkill: this.latestResumeDetails.skills?.join(', ') || '',
          currentCTC: this.latestResumeDetails.currentCTC || '',
          email: this.latestResumeDetails.email || '',
          mobileNumber: this.latestResumeDetails.phone || '',
          expectedCTC: this.latestResumeDetails.expectedCTC || '',
          dob: this.latestResumeDetails.dob ? new Date(this.latestResumeDetails.dob) : '',
          workLocation: this.latestResumeDetails.workLocation || '',
          gender: this.latestResumeDetails.gender.value || '',
          visaStatus: this.latestResumeDetails.visaStatus || '',
          jobId: '',
          relocationStatus: this.latestResumeDetails.relocationStatus || '',
          noticePeriod :this.latestResumeDetails.noticePeriod || '',
          currency: this.latestResumeDetails.currency || '',
          currencyCTC:this.latestResumeDetails.currencyCTC || '',
        });
      }
    })

  }

  onSubmit(): void {
    const formattedDob = this.datePipe.transform(this.candidateForm.value.dob, 'yyyy-MM-dd');
    const formattedFormValue = {
      ...this.candidateForm.value,
      dob: formattedDob
    };
    console.log(formattedFormValue);
    this.updatedForm = formattedFormValue
    this.updateCandidateForm();

  }

  updateCandidateForm() {
    this._candidate.updateCandidateForm(this.updatedForm, this.candidateIdForResume).subscribe({
      next: (res: any) => {
        this._snakbar.openSnackBar_(res.data.message, 'success')
        this.addCandidate();
      },
      error: (err: any) => {
        this._snakbar.openSnackBar_(err.error.data.message, 'error')
      }
    })
  }

  addCandidate() {
    let data = {
      "jobId": this.updatedForm.jobId,
      "candidateId": this.candidateIdForResume,
      "currentCTC": this.updatedForm.currentCTC,
      "expectedCTC": this.updatedForm.expectedCTC,
      "expectedCTCcurrency":this.updatedForm.currency,
      "currentCTCcurrency":this.updatedForm.currencyCTC,
      "noticePeriod": this.updatedForm.noticePeriod,
      "visaStatus": this.updatedForm.visaStatus,
    }
    this._candidate.addCandidatesToJob(this.userId, data).subscribe({
      next: (res: any) => {
        this._snakbar.openSnackBar_(res.data.message, 'success')
        this.showRawResume = false;
        this.showCandidateForm = false;
        let data = { 
          showRawResume: this.showRawResume,
          showCandidateForm: this.showCandidateForm
        };
        this.jobDataService.conditions(data);
        
      },
      error: (err: any) => {
        this._snakbar.openSnackBar_(err.error.data.message, 'error')
      }
    })
  }

  backToCandDetails() {
    this.router.navigate(['/candidates'])
    this.showRawResume = false;
    this.showCandidateForm = false;
    let data = {
      showRawResume: this.showRawResume,
      showCandidateForm: this.showCandidateForm
    };
    this.jobDataService.conditions(data)
  }
  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.key.charCodeAt(0);
  
    if (
      !(charCode >= 48 && charCode <= 57) && 
      event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault();
    }
  }
}
