import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client'; // Correctly importing Socket type
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket | undefined; // Correct type for socket
  private notificationsSubject = new Subject<any>();

  constructor() {}

  public connect(userId: string, role: string, token: string): void {
    this.socket = io(environment.WebSocketUrl, {
      query: {
        userId: userId,
        role: role,
        token: token,
      },
    });

    this.socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    this.socket.on('connect_error', (error: any) => {
      console.error('Connection error:', error);
    });

    this.socket.on('newNotification', (data: any) => {
      console.log('New notification received:', data);
      this.notificationsSubject.next(data);
    });
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined; // Set to undefined after disconnecting
      console.log('Socket connection closed');
    }
  }

  public getNotifications(): Subject<any> {
    return this.notificationsSubject;
  }
}
