import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const openDivAnimation = trigger('openDiv', [
  transition(':enter', [
    style({ height: '0', overflow: 'hidden' }),
    animate('0.5s', style({ height: '*' })),
  ]),
  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    animate('0.5s', style({ height: '0' })),
  ]),
]);

export const deleteNotification = trigger('slideLeft', [
  state('in', style({ transform: 'translateX(0)' })),
  transition(':leave', [
    animate('300ms ease-in', style({ transform: 'translateX(-100%)' })),
  ]),
]);

export const sideNavAnimation =  trigger('sidenavAnimation', [
  state('open', style({
    transform: 'translateX(0)'
  })),
  state('closed', style({
    transform: 'translateX(0)'
  })),
  transition('open => closed', [
    animate('15s ease-in') 
  ]),
  transition('closed => open', [
    animate('15s ease-out')
  ])
])
