import { Component } from '@angular/core';

@Component({
  selector: 'dcps-illustrator',
  templateUrl: './illustrator.component.html',
  styleUrls: ['./illustrator.component.scss'],
})
export class IllustratorComponent {
  openPage(domain: string) {
    if (domain == 'linkedIn') {
      window.open(
        'https://www.linkedin.com/company/delivery-centric-pty-ltd',
        '_blank'
      );
    } else if (domain == 'dc') {
      window.open('https://www.deliverycentric.com/', '_blank');
    } else if (domain == 'meta') {
      window.open('https://www.facebook.com/deliverycentric', '_blank');
    } else if (domain == 'x') {
      window.open('https://twitter.com/deliverycentric', '_blank');
    } else if (domain == 'insta') {
      window.open('https://www.instagram.com/delivery.centric/', '_blank');
    } else if (domain == 'naukri') {
      window.open(
        'https://www.naukri.com/delivery-centric-technologies-overview-4590793?tab=jobs&searchId=17241486849274280_25&src=orgCompanyListing',
        '_blank'
      );
    } else if (domain == 'seek') {
      window.open(
        'https://www.seek.com.au/companies/delivery-centric-813331',
        '_blank'
      );
    }
  }
}
