import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService } from 'src/_providers/_http/candidate.service';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';
import { SharedService } from 'src/_providers/_http/shared.service';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss'],
})
export class EditFormComponent implements OnInit {

  candidateForm: FormGroup;
  noticePeriods: string[] = ['15', '30', '45', '60', '75', '90'];
  updatedForm: any;
  latestResumeDetails: any;
  userId: any;
  selectedJobId: any;
  candidateDetails: number;
  candidateIdForResume: any;
  today: Date = new Date();

  dateFilter = (date: Date | null): boolean => {
    const day = date || new Date();
    return day < this.today;
  };

  constructor(private fb: FormBuilder,
    private _candidate: CandidateService,
    private datePipe: DatePipe,
    private jobDataService: SharedService,
    private _ls_service: LocalStorageService,
    private _snakbar: SnackbarService,
    private router: Router,
    private activeRoute: ActivatedRoute,
  ) {
    this.candidateForm = this.fb.group({
      dob: [''],
    });
  }

  ngOnInit(): void {
    this.userId = this._ls_service.getItem('loggedInUser').userId;
    this.candidateForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      skills: ['', Validators.required],
      currentCTC: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      noticePeriod: ['', Validators.required],
      phone: ['', Validators.required],
      expectedCTC: ['', Validators.required],
      dob: ['', Validators.required],
      workLocation: ['', Validators.required],
      gender: ['', Validators.required],
      visaStatus: ['', Validators.required],
      jobId: ['', Validators.required],
      relocationStatus: ['', Validators.required],
      currency: ['', Validators.required],
      currencyCTC: ['', Validators.required],
    });

    this.jobDataService.resumeData$.subscribe((res) => {

      if (res) {
        this.latestResumeDetails = res.data.savedCandidate;
        this.candidateForm.patchValue({
          firstName: this.latestResumeDetails.firstName || '',
          lastName: this.latestResumeDetails.lastName || '',
          skills: this.latestResumeDetails.skills?.join(', ') || '',
          currentCTC: this.latestResumeDetails.currentCTC || '',
          email: this.latestResumeDetails.email || '',
          phone: this.latestResumeDetails.phone || '',
          expectedCTC: this.latestResumeDetails.expectedCTC || '',
          dob: this.latestResumeDetails.dob ? new Date(this.latestResumeDetails.dob) : '',
          workLocation: this.latestResumeDetails.workLocation || '',
          gender: this.latestResumeDetails.gender || '',
          visaStatus: this.latestResumeDetails.visaStatus || '',
          jobId: '',
          relocationStatus: this.latestResumeDetails.relocationStatus || '',
          noticePeriod :this.latestResumeDetails.noticePeriod || '',
          currency: this.latestResumeDetails.currency || '',
          currencyCTC:this.latestResumeDetails.currencyCTC || '',
        });

      }
    });

    this.activeRoute.params.subscribe((params) => {
      this.selectedJobId = params['jobId'];
    }
    );
    // this.jobDataService.jobData$.subscribe((res) => {
    //   this.selectedJobId = res.jobId;
    // })

    // formExistingCandidates data
    this.jobDataService.resumeData$.subscribe((res) => {
      this.candidateDetails = res;
      this.candidateIdForResume = this.candidateDetails;
      this.CandidateDetails()
    })
  }

  CandidateDetails() {
    this._candidate.getCandidateDetails(this.candidateIdForResume).subscribe({
      next: (res: any) => {
        this.latestResumeDetails = res.data.candidateData;
        this.candidateForm.patchValue({
          firstName: this.latestResumeDetails.firstName || '',
          lastName: this.latestResumeDetails.lastName || '',
          skills: this.latestResumeDetails.skills?.join(', ') || '',
          currentCTC: this.latestResumeDetails.currentCTC || '',
          email: this.latestResumeDetails.email || '',
          phone: this.latestResumeDetails.phone || '',
          expectedCTC: this.latestResumeDetails.expectedCTC || '',
          dob: this.latestResumeDetails.dob ? new Date(this.latestResumeDetails.dob) : '',
          workLocation: this.latestResumeDetails.workLocation || '',
          gender: this.latestResumeDetails.gender || '',
          visaStatus: this.latestResumeDetails.visaStatus || '',
          jobId: '',
          relocationStatus: this.latestResumeDetails.relocationStatus || '',
          noticePeriod :this.latestResumeDetails.noticePeriod || '',
          currency: this.latestResumeDetails.currency || '',
          currencyCTC:this.latestResumeDetails.currencyCTC || '',
        });
      }
    })

  }

  onSubmit(): void {
    const formattedDob = this.datePipe.transform(this.candidateForm.value.dob, 'yyyy-MM-dd');
    const formattedFormValue = {
      ...this.candidateForm.value,
      dob: formattedDob
    };
    this.updatedForm = formattedFormValue
    this.updateCandidateForm();
    // if (this.candidateForm.valid) {
    //   // console.log(this.candidateForm.value);
    // } else {
    //   console.error('Form is invalid');
    //   this._snakbar.openSnackBar_('Form is invalid', 'error')
    // }
  }

  updateCandidateForm() {
    this._candidate.updateCandidateForm(this.updatedForm, this.latestResumeDetails.candidateId || this.candidateIdForResume).subscribe({
      next: (res: any) => {
        this._snakbar.openSnackBar_(res.data.message, 'success')
        this.addCandidate();
      },
      error: (err: any) => {
        this._snakbar.openSnackBar_(err.error.data.message, 'error')
      }
    })
  }

  addCandidate() {
    let data = {
      "jobId": this.selectedJobId,
      "candidateId": this.latestResumeDetails.candidateId || this.candidateIdForResume,
      "currentCTC": this.updatedForm.currentCTC,
      "expectedCTC": this.updatedForm.expectedCTC,
      "expectedCTCcurrency":this.updatedForm.currency,
      "currentCTCcurrency":this.updatedForm.currencyCTC,
      "noticePeriod": this.updatedForm.noticePeriod,
      "visaStatus": this.updatedForm.visaStatus,
    }
    this._candidate.addCandidatesToJob(this.userId, data).subscribe({
      next: (res: any) => {
        this._snakbar.openSnackBar_(res.data.message, 'success')
        this.router.navigate(['/jobs'])
      },
      error: (err: any) => {
        this.router.navigate(['/jobs'])
        // this._snakbar.openSnackBar_(err.error.data.message,'error')
      }
    })
  }

  formCancel(){
    window.location.reload();
  }

  // -------
  allowOnlyAlphabets(event: KeyboardEvent): void {
    const charCode = event.key.charCodeAt(0);
    if (
      !(charCode >= 65 && charCode <= 90) && 
      !(charCode >= 97 && charCode <= 122) && 
      event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault();
    }
  }

  allowOnlyNumbersAndPlus(event: KeyboardEvent): void {
    const charCode = event.key.charCodeAt(0);
  
    if (
      !(charCode >= 48 && charCode <= 57) && 
      event.key !== '+' &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault();
    }
  }
  
  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.key.charCodeAt(0);
  
    if (
      !(charCode >= 48 && charCode <= 57) && 
      event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault();
    }
  }
  
  
}
