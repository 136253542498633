<mat-icon style="cursor: pointer;float: right;" matTooltip="close" (click)="openLink($event)">close</mat-icon>
<mat-nav-list>
    <a  mat-list-item>
      <span matListItemTitle>Google Keep</span>
      <span matLine>Add to a note</span>
    </a>
  
    <a mat-list-item>
      <span matListItemTitle>Google Docs</span>
      <span matLine>Embed in a document</span>
    </a>
  
    <a  mat-list-item>
      <span matListItemTitle>Google Plus</span>
      <span matLine>Share with your friends</span>
    </a>
  
    <a  mat-list-item>
      <span matListItemTitle>Google Hangouts</span>
      <span matLine>Show to your coworkers</span>
    </a>
  </mat-nav-list>