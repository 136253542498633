<div class="form-container" style="margin: 0px">
  <form [formGroup]="candidateForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
      <div class="form-group">
        <label for="lastName">First Name *</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="firstName"
            placeholder="...."
            formControlName="firstName"
            (keydown)="allowOnlyAlphabets($event)"
          />
        </mat-form-field>
      </div>

      <div class="form-group">
        <label for="lastName">Last Name *</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="lastName"
            placeholder="...."
            formControlName="lastName"
            (keydown)="allowOnlyAlphabets($event)"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group primary-skill-group">
        <label for="primarySkill">Primary Skill *</label>
        <mat-form-field appearance="outline">
          <textarea
            style="height: 120px"
            matInput
            id="primarySkill"
            placeholder="...."
            formControlName="skills"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="form-column">
        <div class="form-group" style="margin-bottom: 30px">
          <label for="currentCTC">Current CTC *</label>
          <div class="currency-ctc-container">
            <mat-form-field
              class="currencyCls"
              appearance="outline"
              style="font-size: 10px !important"
            >
              <mat-select formControlName="currencyCTC">
                <mat-option value="inr">INR</mat-option>
                <mat-option value="aud">AUD</mat-option>
                <mat-option value="nzd">NZD</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="ctcClass">
              <input
                matInput
                id="currentCTC"
                placeholder="...."
                formControlName="currentCTC"
                (keydown)="allowOnlyNumbers($event)"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label for="expectedCTC">Expected CTC *</label>
            <div class="currency-ctc-container">
              <mat-form-field
                class="currencyCls"
                appearance="outline"
                style="font-size: 10px !important"
              >
                <mat-select formControlName="currency">
                  <mat-option value="inr">INR</mat-option>
                  <mat-option value="aud">AUD</mat-option>
                  <mat-option value="nzd">NZD</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="ctcClass">
                <input
                  matInput
                  id="expectedCTC"
                  placeholder="...."
                  formControlName="expectedCTC"
                  (keydown)="allowOnlyNumbers($event)"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label for="firstName">Email *</label>
        <mat-form-field appearance="outline" class="form-field">
          <input matInput formControlName="email" placeholder="...." />
        </mat-form-field>
      </div>
      <div class="form-group">
        <label for="firstName">Notice Period *</label>
        <mat-form-field appearance="outline" class="form-field">
          <mat-select
            placeholder="Select no of days"
            formControlName="noticePeriod"
          >
            <mat-option *ngFor="let period of noticePeriods" [value]="period">{{
              period
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label for="firstName">Mobile Number *</label>
        <mat-form-field appearance="outline" class="form-field">
          <input
            matInput
            formControlName="phone"
            (keydown)="allowOnlyNumbersAndPlus($event)"
          />
        </mat-form-field>
      </div>
      <div class="form-group">
        <label for="firstName">Current Location *</label>
        <mat-form-field appearance="outline" class="form-field">
          <input
            matInput
            formControlName="workLocation"
            placeholder="...."
            (keydown)="allowOnlyAlphabets($event)"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label for="dob">DOB</label>
        <mat-form-field appearance="outline" class="form-field">
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="dob"
            [matDatepickerFilter]="dateFilter"
            placeholder="YYYY-MM-DD"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-group">
        <label for="firstName">Visa Status *</label>
        <mat-form-field appearance="outline" class="form-field">
          <input
            matInput
            formControlName="visaStatus"
            placeholder="...."
            (keydown)="allowOnlyAlphabets($event)"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label for="firstName">Gender *</label>
        <mat-form-field appearance="outline" class="form-field">
          <!-- <input matInput formControlName="gender" /> -->
          <mat-select formControlName="gender">
            <mat-option value="Male">Male</mat-option>
            <mat-option value="Female">Female</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <label for="firstName">Relocation Status *</label>
        <mat-form-field appearance="outline" class="form-field">
          <input
            matInput
            formControlName="relocationStatus"
            (keydown)="allowOnlyAlphabets($event)"
            placeholder="...."
          />
        </mat-form-field>
      </div>
    </div>

    <!-- <div class="form-row">
      <div class="form-group">
        <label for="firstName">Add Job ID</label>
        <mat-form-field appearance="fill" class="form-field">
          <input matInput formControlName="jobId" />
        </mat-form-field>
      </div>
    </div> -->

    <div class="form-actions">
      <button
        mat-button
        type="button"
        class="cancel-button"
        (click)="formCancel()"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="save-button"
      >
        Save
      </button>
    </div>
  </form>
</div>
