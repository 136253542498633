import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';

export class HeadersConstants {
  private localStorage = new LocalStorageService();

  public static get noTokenHeaders() {
    const role = JSON.parse(localStorage.getItem('role') || '{}').role;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        role: role,
      }),
    };
    return httpOptions;
  }

  public static get plainHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return httpOptions;
  }

  public static getTokenHeader(): HttpHeaders {
    const roleData = localStorage.getItem('role');
    const role = roleData ? JSON.parse(roleData).role : '';

    const tokenDataString = localStorage.getItem('token'); 
    let token = '';

    if (tokenDataString) {
        token = tokenDataString;
    }
    const headers = new HttpHeaders({
        'role': role,
        'Authorization': token ? `Bearer ${token}` : '', 
    });
    
    return headers;
}


}
