import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
// import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/material.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxEditorModule } from 'ngx-editor';

//components
import { AlertDailogComponent } from './alert-dailog/alert-dailog.component';
import { CalanderComponent } from './calander/calander.component';
import { NavListComponent } from './nav-list/nav-list.component';
import { ChartsComponent } from './charts/charts.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DialogElementsDialog, RecruiterListComponent } from './recruiter-list/recruiter-list.component';
import { AvatarComponent } from './avatar/avatar.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CustomTabsComponent } from './custom-tabs/custom-tabs.component';
import { LoaderComponent } from './loader/loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StatusBoradComponent } from './status-borad/status-borad.component';
import { JobSummaryComponent } from './job-summary/job-summary.component';
import { RouterModule } from '@angular/router';
import { IllustratorComponent } from './illustrator/illustrator.component';
import { EditFormComponent } from './edit-form/edit-form.component';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { GauthDailogComponent } from './gauth-dailog/gauth-dailog.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { ResetPwdDailogComponent } from './reset-pwd-dailog/reset-pwd-dailog.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { AlertNotificationComponent } from './alert-notification/alert-notification.component';
import { CandidateFormComponent } from './candidate-form/candidate-form.component';
import { FilterComponent } from './filter/filter.component';
import { ProfileDailogComponent } from './profile-dailog/profile-dailog.component';
import { LoaderBarComponent } from './loader-bar/loader-bar.component';

//components

@NgModule({
  declarations: [
    AlertDailogComponent,
    CalanderComponent,
    NavListComponent,
    ChartsComponent,
    NotificationsComponent,
    RecruiterListComponent,
    AvatarComponent,
    PagenotfoundComponent,
    CustomTabsComponent,
    LoaderComponent,
    StatusBoradComponent,
    JobSummaryComponent,
    IllustratorComponent,
    EditFormComponent,
    BottomSheetComponent,
    GauthDailogComponent,
    TextEditorComponent,
    DialogElementsDialog,
    ResetPwdDailogComponent,
    SnackbarComponent,
    AlertNotificationComponent,
    CandidateFormComponent,
    FilterComponent,
    ProfileDailogComponent,
    LoaderBarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEditorModule,
    MaterialModule,
    NgxOtpInputModule,
    HighchartsChartModule,
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      loadingText: 'This item is loading...',
    }),
  ],
  providers: [DatePipe ],
  bootstrap: [],
  exports: [
    CalanderComponent,
    NavListComponent,
    ChartsComponent,
    NotificationsComponent,
    RecruiterListComponent,
    AvatarComponent,
    PagenotfoundComponent,
    CustomTabsComponent,
    LoaderComponent,
    StatusBoradComponent,
    JobSummaryComponent,
    IllustratorComponent,
    EditFormComponent,
    BottomSheetComponent,
    TextEditorComponent,
    GauthDailogComponent,
    DialogElementsDialog,
    ResetPwdDailogComponent,
    CandidateFormComponent,
    NgxSkeletonLoaderModule,
    LoaderBarComponent
  ],
})
export class SharedModules {}
