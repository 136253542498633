import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';

export const roleGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const _snackBar = inject(SnackbarService)

  const storedRole = localStorage.getItem('role');
  const userRole = storedRole ? JSON.parse(storedRole).role : null;

  const requiredRoles = route.data['roles'] as string[];

  if (requiredRoles.includes(userRole)) {
    return true;
  }
  _snackBar.openSnackBar_('You are not authorised!!', 'error');
  // router.navigate(['/unauthorized']);
  return false;
};
