<div>
  <p>
    <mat-toolbar>
      <span>Filter by {{ data.filter }}</span>
      <span class="spacer"></span>
      <button mat-icon-button matTooltip="close" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </p>
  <div *ngIf="data.filter == 'priority'">
    <div mat-dialog-content>
      <p>
        <mat-checkbox [(ngModel)]="selectedFilters['critical']"
          >Critical
          <mat-icon style="color: #f44336">error</mat-icon></mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox [(ngModel)]="selectedFilters['urgent']"
          >Urgent
          <mat-icon style="color: #ff5722"
            >priority_high</mat-icon
          ></mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox [(ngModel)]="selectedFilters['important']"
          >Important
          <mat-icon style="color: #ff9800">star</mat-icon></mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox [(ngModel)]="selectedFilters['medium_priority']"
          >Medium Priority
          <mat-icon style="color: #8bc34a">adjust</mat-icon></mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox [(ngModel)]="selectedFilters['high_priority']"
          >High Priority
          <mat-icon style="color: #ffc107">arrow_upward</mat-icon></mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox [(ngModel)]="selectedFilters['low_priority']"
          >Low Priority
          <mat-icon style="color: #2196f3"
            >arrow_downward</mat-icon
          ></mat-checkbox
        >
      </p>
    </div>
  </div>

  <div *ngIf="data.filter == 'assignees'">
    <div mat-dialog-content>
      <mat-radio-group class="radio-group" [(ngModel)]="selectedRec">
        <mat-radio-button color="primary"  *ngFor="let rec of recrutersList" [value]="rec">
          {{ rec.profile.firstName }} {{ rec.profile.lastName }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="clearFilters()">Clear Filters</button>
    <button mat-button (click)="applyFilters()">Apply</button>
  </div>
</div>
