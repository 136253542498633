<div class="custom-notification-snackbar">
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div *ngIf="hasPicKey(data.message.profile)" (click)="openAction()">
      <div
        class="profile-picture"
        [style.background-image]="prepareBg(data.message.profile.pic)"
      ></div>
    </div>
    <dcps-avatar
      (click)="openAction()"
      *ngIf="!hasPicKey(data.message.profile)"
      [profileDetails]="data.message"
      [profile]="false"
      class="img"
    ></dcps-avatar>
    <div
      fxLayout="column"
      fxLayoutAlign="space-around start"
      (click)="openAction()"
    >
      <div class="name" *ngIf="data.message.profile">
        {{ data.message.profile.firstName }}
      </div>
      <div class="message">
        <span *ngIf="data.message == 'JobAssignmentToRecruitmentManager'"
          >Job {{ data.message.params[1] }} assigned to you</span
        >
        <span *ngIf="data.message == 'JobAssignmentToRecruiter'"
          >You have been added to the job {{ data.message.params[1] }}</span
        >
        <span *ngIf="data.message == 'CandidateAddedToJob'"
          >Candidate has been added to {{ data.message.params[1] }}</span
        >
        <!-- {{ data.message.name | capitalizeToLowercasePipe }} -->
      </div>
    </div>
    <div>
      <mat-icon class="icon" (click)="closeSnackbar()" matTooltip="close"
        >close</mat-icon
      >
    </div>
  </div>
</div>
