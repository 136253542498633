import { Component, Inject, Input } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/_providers/_http/auth.service';
import { ProfileDailogComponent } from '../profile-dailog/profile-dailog.component';

@Component({
  selector: 'dcps-recruiter-list',
  templateUrl: './recruiter-list.component.html',
  styleUrls: ['./recruiter-list.component.scss'],
})
export class RecruiterListComponent {
  @Input() avatars: any;
  constructor(public dialog: MatDialog, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  hasPicKey(obj: any): boolean {
    return obj != null && typeof obj === 'object' && 'pic' in obj;
  }

  prepareBg(base64: string) {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${`data:image/jpeg;base64,` + base64})`
    );
  }

  expand(data_: string) {
    this.dialog.open(ProfileDailogComponent, {
      width: '500px',
      height: '500px',
      data: data_,
    });
  }

  openDialog(list: any) {
    this.dialog.open(DialogElementsDialog, {
      height: '60%',
      width: '40%',
      data: {
        recruiters: list,
      },
    });
  }

  get remainingNamesTooltip(): string {
    return this.avatars
      .slice(2)
      .map(
        (avatar: any) =>
          avatar.profile.firstName + ' ' + avatar.profile.lastName
      )
      .join(', ');
  }
}

@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: 'dialog-elements-dialog.html',
  styleUrls: ['./recruiter-list.component.scss'],
})
export class DialogElementsDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogElementsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  hasPicKey(obj: any): boolean {
    return 'pic' in obj;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  prepareBg(base64: string) {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${`data:image/jpeg;base64,` + base64})`
    );
  }

  expand(data_: string) {
    this.dialog.open(ProfileDailogComponent, {
      width: '500px',
      height: '500px',
      data: data_,
    });
  }

  getUserNameById(userId: string): string {
    return 'Hithesh';
    // let user: any;
    // this._auth.getProfile(userId).subscribe({
    //   next: (res: any) => {
    //     if (res.status == 'success') {
    //       user = res.data.profileDetails.firstName;
    //     }
    //   },
    //   error: (err: any) => {
    //     user = '******';
    //   },
    // });
    // // return user ? user.profileDetails.profile.firstName : 'Unknown User';
    // return user;
  }
}
