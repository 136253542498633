import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppEndPoints } from 'src/config/end-points.constants';
import { HeadersConstants } from 'src/config/header.constants';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  constructor(private http: HttpClient) {}

  getCountries(query: string): Observable<string[]> {
    let url = AppEndPoints.COUNTRYLIST + query;
    return this.http.get<any[]>(url, { headers: HeadersConstants.getTokenHeader() });
  }

  getCitiesByCountryAndQuery(
    country: string,
    query: string
  ): Observable<string[]> {
    let url =
      AppEndPoints.CITIESLIST + 'countryName=' + country + '&cityName=' + query;
    return this.http.get<any[]>(url, { headers: HeadersConstants.getTokenHeader() });
  }

  createJob(data: any, userId: string) {
    let url = AppEndPoints.CREATEJOB + '?userId=' + userId;
    return this.http.post<any[]>(url, data, { headers: HeadersConstants.getTokenHeader() });
  }

  updateJob(data: any, userId: string) {
    let url = AppEndPoints.UPDATEJOB + '?userId=' + userId;
    return this.http.post<any[]>(url, data, { headers: HeadersConstants.getTokenHeader() });
  }

  getallbyRoles(role: string) {
    let url = AppEndPoints.GETUSERSBYROLES + '?role=' + role;;
    return this.http.get<any[]>(url,{ headers: HeadersConstants.getTokenHeader() })
  }

  getAllJobs(userId:any){
    let url = AppEndPoints.GETALLJOBS + 'userId=' + userId;
    return this.http.get<any[]>(url,{ headers: HeadersConstants.getTokenHeader() })
  }

  getJobDetails(jobId:any){
    let url = AppEndPoints.GET_JOB_DETAILS + "jobId=" + jobId ;
    return this.http.get<any[]>(url, { headers: HeadersConstants.getTokenHeader() });
  }

  getUserDetails(userId:any) {
    let url = AppEndPoints.GET_USER_DETAILS + 'userId=' + userId ;
    return this.http.get<any[]>(url, { headers: HeadersConstants.getTokenHeader() });
  }

  // getJobDetails(jobId: string) {
  //   let url = AppEndPoints.GETJOBDETAILS + jobId;
  //   return this.http.get<any[]>(url, { headers: HeadersConstants.getTokenHeader() });
  // }

  addRecruters(data: any, userId: string) {
    let url = AppEndPoints.ADDRECRUITERS + userId;
    return this.http.post<any[]>(url, data, { headers: HeadersConstants.getTokenHeader() });
  }

  //Dashboard api my submission
  getRecruiterSubmission(userId:any) {
    let url = AppEndPoints.MY_SUBMISSION + userId ;
    return this.http.get<any[]>(url, { headers: HeadersConstants.getTokenHeader() });
  }

  getComments(jobId:any,userId:any){
    let url = AppEndPoints.GET_COMMENTS + 'jobId=' + jobId + '&userId=' + userId ;
    return this.http.get<any[]>(url,{ headers: HeadersConstants.getTokenHeader() })
  }

  addComments(userId:any,data:any){
    let url = AppEndPoints.ADD_COMMENTS +'userId=' + userId ;
    return this.http.post<any[]>(url,data,{ headers: HeadersConstants.getTokenHeader() })
  }
 
}
