import { Component } from '@angular/core';

@Component({
  selector: 'dcps-loader-bar',
  templateUrl: './loader-bar.component.html',
  styleUrls: ['./loader-bar.component.scss']
})
export class LoaderBarComponent {

}
