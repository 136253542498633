import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from 'src/_providers/_http/auth.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private _auth: AuthService,
    private router: Router,
    private _ls: LocalStorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._auth.getToken();
    let authReq = req;

    if (token) {
      authReq = this.addToken(req, token);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null); // Reset the subject to indicate no token available
      return this._auth.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.data.tokens); // Emit the new token
          this._auth.getProfile(token.data.userDetails.userId).subscribe({
            next: (res: any) => {
              this._ls.setItem('profile', res.data.profileDetails);
            },
          });
          return next.handle(this.addToken(request, token)); // Retry the failed request with the new token
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this._auth.logout();
          //temp solution
          window.location.reload();
          // this.router.navigate(['/sign-in']);
          return throwError(() => err); // Return the error if refreshing fails
        })
      );
    } else {
      localStorage.clear();
      // Wait for the refresh token observable to emit a value
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null), // Ensure a valid token is emitted
        take(1), // Take only the next emitted value
        switchMap((jwt) => next.handle(this.addToken(request, jwt))) // Retry the failed request with the new token
      );
    }
  }
}
