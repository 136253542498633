import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const roleGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const storedRole = localStorage.getItem('role');
  const userRole = storedRole ? JSON.parse(storedRole).role : null;

  const requiredRoles = route.data['roles'] as string[];

  if (requiredRoles.includes(userRole)) {
    return true;
  }

  router.navigate(['/unauthorized']);
  return false;
};
