import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dcps-profile-dailog',
  templateUrl: './profile-dailog.component.html',
  styleUrls: ['./profile-dailog.component.scss'],
})
export class ProfileDailogComponent {
  constructor(
    public dialogRef: MatDialogRef<ProfileDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {}

  prepareBg(base64: string) {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${`data:image/jpeg;base64,` + base64})`
    );
  }
}
