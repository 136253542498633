import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { Injectable } from '@angular/core';
import { HeadersConstants } from 'src/config/header.constants';
import { AppEndPoints } from 'src/config/end-points.constants';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(private http: HttpClient) { }

  updateCandidateStatus(data: any,userId:any){
    let url = AppEndPoints.UPDATE_CANDIDATE_STATUS + 'userId=' + userId ;
    return this.http.post<any[]>(url, data, { headers: HeadersConstants.getTokenHeader() });
  }

  addCandidatesToJob(recruiterId:any,data:any){
    let url = AppEndPoints.ADD_CANDIDATE_TO_JOBS + 'recruiterId=' + recruiterId ;
    return this.http.post<any[]>(url, data,{ headers: HeadersConstants.getTokenHeader() });
  }

  addCandidateResume(file:any,refererId:any){
    let url = AppEndPoints.ADD_CANDIDATE_RESUME + 'refererId=' + refererId;
    return this.http.post<any[]>(url,file,{ headers: HeadersConstants.getTokenHeader() });
  }

  updateResume(file:any,candidateId:any,refererId:any){
    let url = AppEndPoints.UPDATE_RESUME  + 'candidateId=' + candidateId + '&refererId=' + refererId;
    return this.http.post<any[]>(url,file,{ headers: HeadersConstants.getTokenHeader() });
  }

  updateCandidateForm(data:any,candidateId:any){
    let url = AppEndPoints.UPDATE_CANDIDATE_FORM  + 'candidateId=' + candidateId;
    return this.http.put<any[]>(url,data,{ headers: HeadersConstants.getTokenHeader() });
  }

  // searchCandidates(query: string): Observable<any[]> {
  //   return this.http.get<any[]>(`${'http://localhost:5000/api/userservice/candidates/filterCandidate?'}/search`, {
  //     params: { q: query }, // Add query as a parameter for search
  //   });
  // }
  
  getCandidateDetails(candidateId:any){
    let url = AppEndPoints.GET_CANDIDATE_DETAILS + 'candidateId=' + candidateId;
    return this.http.get<any[]>(url,{ headers: HeadersConstants.getTokenHeader() });
  }

  getCandidateResume(candidateId:any){
    let url = AppEndPoints.GET_CANDIDATE_RESUME + 'candidateId=' + candidateId;
    return this.http.get<any[]>(url,{ headers: HeadersConstants.getTokenHeader() });
  }

  filterCandidate(nameoremail:any,skillsorjobtitle:any){
    let url = AppEndPoints.FILTER_CANDIDATE + 'nameoremail=' + nameoremail + '&skillsorjobtitle=' + skillsorjobtitle;
    return this.http.get<any[]>(url,{ headers: HeadersConstants.getTokenHeader() });
  }

  updatePinnedStatus(recruiterId:any,candidateId:any,data:any){
    let url = AppEndPoints.UPDATE_PINNED_STATUS + 'recruiterId=' + recruiterId + '&candidateId=' + candidateId;
    return this.http.post<any[]>(url,data,{ headers: HeadersConstants.getTokenHeader() });
  }

  getPinnedCandidates(recruiterId:any){
    let url = AppEndPoints.GET_PINNED_CANDIDATES + 'recruiterId=' + recruiterId ;
    return this.http.get<any[]>(url,{ headers: HeadersConstants.getTokenHeader() });
  }

  managerApproval(data:any,userId:string){
    let url = AppEndPoints.MANAGER_APPROVAL + userId ;
    return this.http.post<any[]>(url,data,{ headers: HeadersConstants.getTokenHeader() });
  }
}
