<form [formGroup]="form">
  <div class="editor">
    <ngx-editor-menu
      [editor]="editor"
      [toolbar]="toolbar"
      *ngIf="!this.disabled"
    >
    </ngx-editor-menu>
    <ngx-editor [editor]="editor" formControlName="editorContent"> </ngx-editor>
  </div>
</form>

<!-- <button (click)="submit()">Submit</button> -->
