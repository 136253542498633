<p>Job ID's</p>
<mat-list role="list" *ngFor="let id of ids">
  <mat-list-item role="listitem" (click)="addTab(true, id)">
    {{ id }}
  </mat-list-item>
</mat-list>

<div>
  <mat-tab-group
    [selectedIndex]="selected.value"
    (selectedIndexChange)="selected.setValue($event)"
  >
    <mat-tab *ngFor="let tab of tabs; let index = index">
      <ng-template mat-tab-label>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          style="width: 100%;"
        >
          <div>{{ tab }}</div>
          <mat-icon
            (click)="removeTab(index)"
            matTooltip="close"
            style="font-size: 22px; cursor: pointer;"
          >
            close
          </mat-icon>
        </div>
      </ng-template>
      <!-- <dcgrs-job-summary></dcgrs-job-summary> -->
    </mat-tab>
  </mat-tab-group>
</div>
