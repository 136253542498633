import { environment } from '../environments/environment';

class AppHost {
  public static get baseURL(): string {
    return environment.ApiBaseUrl;
  }
}

export class AppEndPoints {
  // Basic Authantication Api's
  //1.create recruiter registration with password
  public static REGISTER = AppHost.baseURL + '/userservice/user/registration';

  //2.login, sending login request to check user is registered
  public static LOGINREQUEST = AppHost.baseURL + '/userservice/user/loginreq';

  //3.Acceptng Terms and Condtions
  public static ACCCEPTTANDC =
    AppHost.baseURL + '/userservice/user/termsAndConditions';

  //4.Login api after entering google authantication code
  public static LOGIN = AppHost.baseURL + '/userservice/user/login';

  //5.Get profile details of the loggged in user with userId
  public static PROFILEDETAILS =
    AppHost.baseURL + '/userservice/user/profile/getProfileDetails?';

  // Google Authaticator Api's
  //6.Setting up mfa, genarating qr code first time
  public static SETUPMFA = AppHost.baseURL + '/userservice/user/mfa/setupMfa';

  //7.enabling mfa, after scanning
  public static ENABLEMFA = AppHost.baseURL + '/userservice/user/mfa/enableMfa';

  //8.disabling mfa or removing mfa
  public static DISABLEMFA =
    AppHost.baseURL + '/userservice/user/mfa/disableMfa';

  //9. fetching country list
  public static COUNTRYLIST =
    AppHost.baseURL +
    '/userservice/user/salesManager/getCountriesByQuery?query=';

  //10. fetching cities after choosing country
  public static CITIESLIST =
    AppHost.baseURL + '/userservice/user/salesManager/getCitiesByCountry?';

  //11. Createing Job(sales Manger)
  public static CREATEJOB =
    AppHost.baseURL + '/userservice/salesmanager/createJob';

  //12. Updating the Job(sales Manger)
  public static UPDATEJOB =
    AppHost.baseURL + '/userservice/salesmanager/updateJob';

  //13. Getting Job deails Job(sales Manger, manager)
  public static GETJOBDETAILS =
    AppHost.baseURL + '/userservice/jobs/getJobDetails?jobId=';

  //14. adding Recruiters for job(sales Manger, manager)
  public static ADDRECRUITERS =
    AppHost.baseURL +
    '/userservice/recruitmentmanager/job/addRecruiter?userId=';

  //15. Reset password link genataion (all users)
  public static FORGOTPASSWORDLINK =
    AppHost.baseURL + '/userservice/user/forgetPasswordemailLink';

  //16. UPDATE password (all users)
  public static UPDATEPASSWORD =
    AppHost.baseURL + '/userservice/user/forgetPassword';

  //17 Refresh token api(all Users)
  public static REFRESHTOKEN =
    AppHost.baseURL + '/userservice/user/refreshToken?';

  // -----------salesManager----------------------
  // 1.Create Clints
  public static CREATE_CLIENT =
    AppHost.baseURL + '/userservice/salesmanager/createClient';

  // 2.Get Client Details
  public static GET_CLIENT_DETAILS =
    AppHost.baseURL + '/userservice/salesmanager/getClientDetails?';

  // 3.Get All Clients
  public static GET_ALL_CLIENTS =
    AppHost.baseURL + '/userservice/salesmanager/getAllClients';

  // Create Client Contact
  public static CREATE_CLIENT_CONTACT =
    AppHost.baseURL + '/userservice/salesmanager/createClientContact';

  // Get Client Contact Details
  public static GET_CLIENT_CONTACT_DETAILS =
    AppHost.baseURL + '/userservice/salesmanager/getClientContactDetails?';

  // Get All Client Contacts
  public static GET_ALL_CLIENT_CONTACTS =
    AppHost.baseURL + '/userservice/salesmanager/getAllClientContacts?';

  //Get users by role, all users sending role as parameter
  public static GETUSERSBYROLES =
    AppHost.baseURL + '/userservice/user/getAllUsersByRole/';

  // Get all jobs created by sale manager, can be userd by manager, salesmanger and recruter
  public static GETALLJOBS = AppHost.baseURL + '/userservice/jobs/getAllJobs?';

  //--------------------- JOBS APIS---------------------------------

  // GET ALL JOB DETAILS
  public static GET_JOB_DETAILS =
    AppHost.baseURL + '/userservice/jobs/getJobDetails?';

  // GET perticular USER DETAILS
  public static GET_USER_DETAILS =
    AppHost.baseURL + '/userservice/user/getUserDetails?';

  // --------RECRUITERS Candidate-----------------

  // Update Candidate Status
  public static UPDATE_CANDIDATE_STATUS =
    AppHost.baseURL + '/userservice/recruiter/updateCandidateStatus?';

  // Add Candidate to job
  public static ADD_CANDIDATE_TO_JOBS =
    AppHost.baseURL + '/userservice/recruiter/addCandidateToJob?';

  // Add Candidate Resume
  public static ADD_CANDIDATE_RESUME =
    AppHost.baseURL + '/userservice/candidates/addCandidate?';

  // Update Resume
  public static UPDATE_RESUME =
    AppHost.baseURL + '/userservice/candidates/updateResume?';

  // Update Candidate
  public static UPDATE_CANDIDATE_FORM =
    AppHost.baseURL + '/userservice/candidates/updateCandidate?';

  // Filter Candidate
  public static FILTER_CANDIDATE =
    AppHost.baseURL + '/userservice/candidates/filterCandidate?';

  // Get Candidate Detailds
  public static GET_CANDIDATE_DETAILS =
    AppHost.baseURL + '/userservice/candidates/getCandidateDetails?';

  // Get Candidate Resume
  public static GET_CANDIDATE_RESUME =
    AppHost.baseURL + '/userservice/candidates/getCandidateResume?';

  // Update pinned candidates
  public static UPDATE_PINNED_STATUS =
    AppHost.baseURL + '/userservice/candidates/updatePinnedStatus?';

  // GET Pinned Candidates
  public static GET_PINNED_CANDIDATES =
    AppHost.baseURL + '/userservice/candidates/getPinnedCandidates?';

  // update candidate status (Manager)
  public static MANAGER_APPROVAL =
    AppHost.baseURL +
    '/userservice/recruitmentmanager/job/updateCandidateStatus?userId=';

  //Dashboard API

  public static MY_SUBMISSION =
    AppHost.baseURL + '/userservice/user/getDashboardData?userId=';

  //Notifications(all Users)
  public static GET_ALL_NOTIFICATIONS =
    AppHost.baseURL + '/userservice/notifications/getAllNotifications?';

  public static MARK_NOTIFICATION_READ =
    AppHost.baseURL + '/userservice/notifications/markNotificationAsRead?';

  // GET Comments
  public static GET_COMMENTS =
    AppHost.baseURL + '/userservice/jobs/getComments?';

  // Add Comments
  public static ADD_COMMENTS =
    AppHost.baseURL + '/userservice/jobs/addComment?';

  // get settings data(all users)
  public static SETTINGSDATA =
    AppHost.baseURL + '/userservice/user/settings';

  // Logout (all users)
  public static LOGOUT = AppHost.baseURL + '/userservice/user/logout?';
}
