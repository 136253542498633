import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as Highcharts from 'highcharts';

interface SeriesData {
  type: any;
  name: string;
  data: number[]; // Define this based on the actual data structure
}

@Component({
  selector: 'dcgrs-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnChanges {
  @Input() seriesData: SeriesData;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['seriesData'] && !changes['seriesData'].firstChange) {
      this.updateChart();
      this.changeDetectorRef.detectChanges();
    }
  }

  updateChart() {
    const yAxisValues = Object.keys(this.seriesData);
    const xAxisValues = Object.values(this.seriesData);

    this.chartOptions = {
      chart: {
        type: 'column',
        spacingBottom: 20,
        marginBottom: 220,
      },
      title: {
        text: 'Job Status',
        align: 'left',
        x: 5,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: 'black',
        },
      },
      xAxis: {
        categories: yAxisValues,
        labels: {
          rotation: -45,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          borderColor: 'rgba(4, 150, 255, 1)',
          borderWidth: 1,
          pointWidth: 20,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<span>\u25CF</span>Count: <b>{point.y}</b><br/>',
      },
      series: [
        {
          name: 'Status',
          data: xAxisValues,
          type: 'column',
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, 'rgba(4, 150, 255, 1)'],
              [1, 'rgba(40, 181, 224, 1)'],
            ],
          },
        },
      ],
    };
  }
}
