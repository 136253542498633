<div class="main-container" [ngClass]="{ 'shake-animation': showShake }">
  <mat-icon
    style="float: right; cursor: pointer"
    matTooltip="close"
    (click)="onNoClick()"
    >close</mat-icon
  >
  <div
    fxLayout="column"
    fxLayoutAlign="space-around center"
    style="margin-top: 5%"
  >
    <div>
      <div class="heading">Enter the Google Authenticator code</div>
      <ngx-otp-input
        style="margin-top: 5%"
        [config]="otpInputConfig"
        (otpChange)="handeOtpChange($event)"
        (fill)="handleFillEvent($event)"
      >
      </ngx-otp-input>
      <div
        style="margin-top: 2%"
        fxLayout="row"
        fxLayoutAlign="center center"
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
      >
        <button
          mat-flat-button
          color="primary"
          class="verifybutton"
          (click)="verify()"
        >
          Verify
        </button>
      </div>
    </div>
  </div>
</div>
