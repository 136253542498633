<div class="scroll-container">
    <div
      class="container"
      *ngFor="let container of containers; let i = index"
    >
      <div class="step">
        <h3>Step {{ i + 1 }}</h3>
        <div
          cdkDropList
          [cdkDropListData]="container.items"
          class="drop-list"
          (cdkDropListDropped)="drop($event, i)"
        >
          <div
            class="card"
            *ngFor="let item of container.items"
            cdkDrag
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>