<div>
  <div class="card">
    <p class="title">All Talent Scout Assigned <mat-icon
      style="float: right; cursor: pointer"
      matTooltip="close"
      (click)="onNoClick()"
      >close</mat-icon
    ></p>
    <div class="user__container" *ngFor="let rec of data.recruiters">
      <div class="user">
        <div class="image">
          <dcps-avatar
            *ngIf="!hasPicKey(rec.profile)"
            [profileDetails]="rec"
            [profile]="false"
            class="img"
          ></dcps-avatar>
          <span *ngIf="hasPicKey(rec.profile)" (click)="expand(rec)">
            <div
              class="profile-picture"
              [style.background-image]="prepareBg(rec.profile.pic)"
            ></div>
          </span>
        </div>
        <div class="user__content">
          <div class="text">
            <span class="name"
              >{{rec.profile.firstName}}{{rec.profile.lastName}}</span
            >
            <!-- <p class="username">assigned by {{ getUserNameById(rec.assignedBy.userId) }}</p> -->
          </div>
          <button class="follow">
            {{rec.submittedCandidates.length}} Profiles
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
