import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';

@Component({
  selector: 'dcgrs-status-borad',
  templateUrl: './status-borad.component.html',
  styleUrls: ['./status-borad.component.scss'],
})
export class StatusBoradComponent {
  containers = [
    { items: ['Item 1', 'Item 2', 'Item 3'] },
    { items: ['Item 4', 'Item 5'] },
    { items: ['Item 6', 'Item 7'] },
    { items: ['Item 8'] },
    { items: ['Item 9', 'Item 10'] },
    { items: ['Item 11', 'Item 12'] },
    { items: ['Item 13', 'Item 14'] },
  ];
  startIndex = 0;

  drop(event: CdkDragDrop<string[]>, containerIndex: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}
