<div class="venn-container">
  <div class="circle circle1"></div>
  <div class="circle circle2"></div>
  <div class="circle circle3"></div>
  <div
    class="logo-container logo1"
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltip="instagram"
    (click)="openPage('insta')"
  >
    <img
      src="./../../../assets/illustrator/Group.png"
      height="35px"
      width="35px"
    />
  </div>
  <div
    class="logo-container logo2"
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltip="linkedIn"
    (click)="openPage('linkedIn')"
  >
    <img
      src="./../../../assets/illustrator/devicon_linkedin.png"
      height="35px"
      width="35px"
    />
  </div>
  <div
    class="logo-container logo3"
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltip="meta"
    (click)="openPage('meta')"
  >
    <img
      src="./../../../assets/illustrator/logos_facebook.png"
      height="35px"
      width="35px"
    />
  </div>
  <div
    class="logo-large-container logo4"
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltip="Delivery Centric"
    (click)="openPage('dc')"
  >
    <img src="./../../../assets/dc_logo.png" height="40px" width="35px" />
  </div>
  <div
    class="logo-container logo5"
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltip="X"
    (click)="openPage('x')"
  >
    <img
      src="./../../../assets/illustrator/Group 2085662993.png"
      height="40px"
      width="35px"
    />
  </div>
  <div
    class="logo-container logo6"
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltip="seek"
    (click)="openPage('seek')"
  >
    <img
      src="./../../../assets/illustrator/seek-logo.png"
      height="40px"
      width="35px"
    />
  </div>
  <div
    class="logo-container logo7"
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltip="naukri"
    (click)="openPage('naukri')"
  >
    <img
      src="./../../../assets/illustrator/naukri.png"
      height="40px"
      width="35px"
    />
  </div>
</div>
