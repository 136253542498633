<div class="avatar-container">
  <ng-container *ngFor="let avatar of avatars; let i = index">
    <span
      *ngIf="i < 2 && avatar.profile"
      class="avatar-card"
      matTooltip="{{
        avatar.profile.firstName + ' ' + avatar.profile.lastName
      }}"
    >
    <span *ngIf="hasPicKey(avatar.profile)">
      <div
        class="profile-picture_"
        [style.background-image]="prepareBg(avatar.profile.pic)"
        (click)="expand(avatar)"
      ></div>
    </span>
      <dcps-avatar *ngIf="!hasPicKey(avatar.profile)"
        [profileDetails]="avatar"
        [profile]="false"
        class="img"
      ></dcps-avatar>
    </span>
    <span
      (click)="openDialog(avatars)"
      *ngIf="i === 2 && avatar.profile"
      class="avatar-card remaining-count"
      [matTooltip]="remainingNamesTooltip"
    >
      <div class="count-overlay">+ {{ avatars.length - 2 }}</div>
    </span>
  </ng-container>
</div>
