<ngx-skeleton-loader
  *ngIf="notificationsDataLoading"
  count="2"
  [theme]="{ 'border-radius': '5px', height: '70px' }"
></ngx-skeleton-loader>
<div
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="notifications.length == 0 && !notificationsDataLoading"
>
  <mat-card class="no-notification-card">
    <img mat-card-image src="./../../assets/illustrator/EmptyState.svg" />
    <mat-card-content>
      <span fxLayout="column" fxLayoutAlign="space-around center">
        <div class="no-inbox-heading">Your inbox is empty</div>
        <div class="no-inbox-desc">You have no active notifications</div>
      </span>
    </mat-card-content>
  </mat-card>
</div>
<div
  class="main-container"
  *ngIf="notifications.length > 0 && !notificationsDataLoading"
>
  <div class="heading">Notifications</div>
  <mat-divider class="underline"></mat-divider>
  <span>
    <div
      class="notification-list"
      *ngFor="let note of notifications; let i = index"
      fxLayout="row"
      fxLayoutAlign="space-between"
      (click)="openNotification(note)"
    >
      <div
        style="height: 100%; width: 8px; border-radius: 6px"
        [ngStyle]="{ 'background-color': getBackgroundColor(0) }"
      ></div>
      <div style="margin-left: 5%; width: 100%">
        <div
          class="sender-name"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngIf="note.profile"
        >
          <div>{{ note.profile.firstName }} {{ note.profile.lastName }}</div>
          <div class="timeStamp">
            <span>{{ note.timeStamp | date : "dd/MM/yyyy" }}</span>
            &nbsp;
            <span>{{ note.timeStamp | date : "hh:mm a" }}</span>
          </div>
        </div>
        <div class="message">{{ note.message }}</div>
      </div>
    </div>

    <div>
      <button mat-button color="primary" (click)="all()">
        All notifications
      </button>
    </div>
  </span>
</div>
