import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dcps-reset-pwd-dailog',
  templateUrl: './reset-pwd-dailog.component.html',
  styleUrls: ['./reset-pwd-dailog.component.scss'],
})
export class ResetPwdDailogComponent {
  emailForm: FormGroup;
  email = '';

  constructor(
    public dialogRef: MatDialogRef<ResetPwdDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  send() {
    Object.assign(this.data, { confirmData: true });
    this.dialogRef.close(this.data);
  }

  attachEmailAndSend() {
    Object.assign(this.data, { email: this.email, confirmData: true });
    this.dialogRef.close(this.data);
  }
}
