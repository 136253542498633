import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserverService } from 'src/_providers/breakpoint-observer.service';

@Component({
  selector: 'dcgrs-alert-dailog',
  templateUrl: './alert-dailog.component.html',
  styleUrls: ['./alert-dailog.component.scss'],
})
export class AlertDailogComponent implements OnInit {
  dailogWidth = '0px';
  dailogHeight = '0vh';
  iconWidth = '0px';
  iconHeight = '0px';
  constructor(
    public dialogRef: MatDialogRef<AlertDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public brkPoint: BreakpointObserverService
  ) {
    // this.brkPoint.size$.subscribe((val) => {
    //   if (val == 'xs') {
    //     this.dailogWidth = '200px';
    //     this.dailogHeight= '200px';
    //     this.iconHeight = this.iconWidth = '60px'
    //   } else if (val == 'sm') {
    //     this.dailogWidth = '200px';
    //     this.dailogHeight= '200px';
    //     this.iconHeight = this.iconWidth = '60px'
    //   } else if (val == 'md') {
    //     this.dailogWidth = '300px';
    //     this.dailogHeight= '250px';
    //     this.iconHeight = this.iconWidth = '80px'
    //   } else if (val == 'lg') {
    //     this.dailogWidth = '300px';
    //     this.dailogHeight= '300px';
    //     this.iconHeight = this.iconWidth = '100px'
    //   } else if (val == 'xl') {
    //     this.dailogWidth = '500px';
    //     this.dailogHeight= '400px';
    //     this.iconHeight = this.iconWidth = '120px'
    //   }
    // });
  }

  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
