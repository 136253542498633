import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertNotificationComponent } from 'src/_shared/_components/alert-notification/alert-notification.component';
import { SnackbarComponent } from 'src/_shared/_components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar_(
    message: string,
    snackBarType: 'success' | 'error' | 'info' | 'warning'
  ) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: { message, snackBarType },
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar', snackBarType],
    });
  }

  openNotificationSnackBar_(message: any){
    this._snackBar.openFromComponent(AlertNotificationComponent, {
      data: { message },
      duration: 8000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: ['custom-notification-snackbar'],
    });
  }
}
