import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/_providers/_http/auth.service';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private _auth: AuthService,
    private router: Router,
    private _snackbar: SnackbarService
  ) {}

  canActivate(): boolean {
    if (this._auth.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/sign-in']);
      this._snackbar.openSnackBar_('You have been logged out !!!!', 'info');
      return false;
    }
  }
}
