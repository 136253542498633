import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobsService } from 'src/_providers/_http/jobs.service';
import { SharedService } from 'src/_providers/_http/shared.service';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';

@Component({
  selector: 'dcps-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  selectedFilters: { [key: string]: boolean } = {};
  filterData: any;
  recrutersList: any;
  selectedRec: any;

  constructor(
    public dialogRef: MatDialogRef<FilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _shared: SharedService,
    private _jobs: JobsService,
    private _snackbar: SnackbarService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getData();
  }

  convertToSelectedFilters(input: { [key: string]: string }): {
    [key: string]: boolean;
  } {
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        this.selectedFilters[key] = false;
      }
    }

    return this.selectedFilters;
  }

  getData() {
    if (this.data.filter == 'assignees') {
      this._jobs.getallbyRoles('recruiter').subscribe({
        next: (res: any) => {
          if (res.status == 'success' && res.code == 200) {
            this.recrutersList = res.data.usersList;
            // .map((user: any) => ({
            //   ...user,
            //   selected: false,
            // }));
          }
        },
        error: (err: any) => {
          this._snackbar.openSnackBar_(err.error.data.message, 'error');
        },
      });
    } else if (this.data.filter == 'priority')
      this._shared.getSettings().subscribe({
        next: (res: any) => {
          if (res.status == 'success') {
            if (this.data.filter == 'priority') {
              this.filterData = this.convertToSelectedFilters(
                res.data.settings.JobPriorities
              );
            }
          }
        },
      });
  }

  applyFilters(): void {
    if (this.data.filter == 'priority') {
      this.dialogRef.close(this.selectedFilters);
    } else if (this.data.filter == 'assignees') {
      this.dialogRef.close(this.selectedRec);
    }
  }

  clearFilters(): void {
    this.dialogRef.close({ clear: true });
  }
}
