import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { BreakpointObserverService } from 'src/_providers/breakpoint-observer.service';
import { sideNavAnimation } from './../../../_shared/animation';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from 'src/_providers/_http/shared.service';
import { AuthService } from 'src/_providers/_http/auth.service';
import { SocketService } from 'src/_providers/_http/socket.service';
import { Subscription } from 'rxjs';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [sideNavAnimation],
})
export class SideNavComponent implements OnInit {
  margin: string = '0px 0px';
  sideNavWidth = '0';
  opened: boolean;
  mobileQuery: MediaQueryList;
  @ViewChild('sidenav') sidenav: MatSidenav;
  menuList: any[] = [];
  rec_menus: any[] = [
    {
      name: 'Dashboard',
      icon: 'home_outline',
      link: '/dashboard',
      subMenu: null,
      action: false,
    },
    {
      name: 'Jobs',
      icon: 'work_outline',
      link: '/jobs',
      subMenu: null,
      action: false,
    },
    {
      name: 'Candidates',
      icon: 'group_outline',
      link: '/candidates',
      subMenu: null,
      action: false,
    },
    {
      name: 'Notifications',
      icon: 'notifications',
      link: '/notifications',
      subMenu: null,
      action: true,
    },
  ];

  rec_manager_menus: any[] = [
    {
      name: 'Dashboard',
      icon: 'home_outline',
      link: '/manager_dashboard',
      subMenu: null,
      action: false,
    },
    {
      name: 'Jobs',
      icon: 'work_outline',
      link: '/manager_jobs',
      subMenu: null,
      action: false,
    },
    {
      name: 'Notifications',
      icon: 'notifications',
      link: '/notifications',
      subMenu: null,
      action: true,
    },
  ];

  sales_manager_menus: any[] = [
    {
      name: 'Dashboard',
      icon: 'home_outline',
      link: '/manager_dashboard',
      subMenu: null,
      action: false,
    },
    {
      name: 'Jobs',
      icon: 'work_outline',
      link: '/manager_jobs',
      subMenu: null,
      action: false,
    },
    {
      name: 'Client Management',
      icon: 'group_outline',
      link: '/managerclients',
      subMenu: null,
      action: false,
    },
    {
      name: 'Notifications',
      icon: 'notifications',
      link: '/notifications',
      subMenu: null,
      action: true,
    },
  ];

  private _mobileQueryListener: () => void;
  message: any;
  miniVersion: boolean;
  profileDetails: any;
  backgroundImage: any = null;
  userRole: any;

  notifications: any[] = [];
  private notificationsSubscription: Subscription;

  constructor(
    public brkPoint: BreakpointObserverService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public router: Router,
    private _ls_service: LocalStorageService,
    private sanitizer: DomSanitizer,
    private _shared: SharedService,
    private _auth: AuthService,
    private webSocketService: SocketService,
    private _snackbar: SnackbarService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.setupBreakpoints();
    this.listenForPicUpdates();
  }

  ngOnInit(): void {
    this.userRole = this._ls_service.getItem('role');
    this.setMenuListBasedOnRole();
    this.connectWebSocket();
    this.decrease();
    this.checkAndUpdatePic();
  }

  private setupBreakpoints(): void {
    this.brkPoint.size$.subscribe((val) => {
      switch (val) {
        case 'xs':
          this.sideNavWidth = '40%';
          this.margin = '0px 0px';
          this.opened = false;
          break;
        case 'sm':
          this.margin = '0px 100px';
          this.sideNavWidth = '30%';
          this.opened = false;
          break;
        case 'md':
        case 'lg':
          this.margin = '0px 100px';
          this.sideNavWidth = '20%';
          this.opened = true;
          break;
        case 'xl':
          this.margin = '0px 120px';
          this.sideNavWidth = '20%';
          this.opened = true;
          break;
      }
    });
  }

  private listenForPicUpdates(): void {
    this._shared.updatedPic$.subscribe((updated) => {
      if (updated) {
        this.checkAndUpdatePic();
        this._shared.setValue(false);
      }
    });
  }

  private setMenuListBasedOnRole(): void {
    switch (this.userRole?.role) {
      case 'recruiter':
        this.menuList = this.rec_menus;
        break;
      case 'recruitmentmanager':
        this.menuList = this.rec_manager_menus;
        break;
      case 'salesmanager':
        this.menuList = this.sales_manager_menus;
        break;
    }
  }

  private connectWebSocket(): void {
    const userId = this._ls_service.getItem('profile')?.userId;
    const token = this._ls_service.getItem('token')?.token;
    this.webSocketService.connect(userId, this.userRole?.role, token);
    this.notificationsSubscription = this.webSocketService
      .getNotifications()
      .subscribe((notification) => {
        this._snackbar.openNotificationSnackBar_(notification);
        this.notifications.push(notification);
      });
  }

  checkAndUpdatePic(): void {
    this.profileDetails = this._ls_service.getItem('profile');
    if (!this.hasPicKey(this.profileDetails?.profile)) {
      this.backgroundImage = null;
    } else {
      this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
        `url(data:image/jpeg;base64,${this.profileDetails.profile.pic})`
      );
    }
  }

  hasPicKey(obj: any): boolean {
    return obj != null && typeof obj === 'object' && 'pic' in obj;
  }

  increase(): void {
    this.sideNavWidth = '20%';
    this.receiveMessage(false);
  }

  decrease(): void {
    this.sideNavWidth = '6%';
    this.receiveMessage(true);
  }

  receiveMessage($event: any): void {
    this.miniVersion = $event;
  }

  logout(): void {
    this._auth.logout().subscribe({
      next: (res: any) => {
        if (res.status === 'success') {
          this.clearTokens();
          this.router.navigate(['/sign-in']);
        }
      },
      error: () => {
        this.clearTokens();
        this.router.navigate(['/sign-in']);
      },
    });
  }

  private clearTokens(): void {
    localStorage.clear();
  }

  toProfile(): void {
    this.router.navigate(['/profile']);
  }

  closeSideNavFromMenu($event: any): void {
    if ($event) {
      this.decrease();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this.webSocketService.disconnect();
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }
}
