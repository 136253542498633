import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Validators, Editor, Toolbar } from 'ngx-editor';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';
import { SharedService } from 'src/_providers/_http/shared.service';

@Component({
  selector: 'dcps-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextEditorComponent implements OnInit, OnDestroy {
  @Input() textData: any;
  @Output() messageEvent = new EventEmitter<any>();
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    // ['underline', 'strike'],
    ['underline'],
    // ['code', 'blockquote'],
    // ['ordered_list', 'bullet_list'],
    ['bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    // ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  form: FormGroup;
  private subscription: Subscription;
  disabled: boolean;
  userDetails: any;

  constructor(
    private sharedService: SharedService,
    private _ls_service: LocalStorageService
  ) {}

  get doc(): AbstractControl | null {
    return this.form.get('editorContent');
  }

  ngOnInit(): void {
    this.userDetails = this._ls_service.getItem('profile');
    if (this.userDetails.role.includes('salesmanager')) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
    this.loadForm();
  }

  loadForm() {
    this.form = new FormGroup({
      editorContent: new FormControl(
        { value: this.textData, disabled: false },
        Validators.required()
      ),
    });
    this.editor = new Editor();

    this.subscription = this.sharedService.submitFunction$.subscribe(() => {
      this.sendDoc();
    });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    this.subscription.unsubscribe();
  }

  ngOnChanges(): void {
    this.loadForm();
  }

  sendDoc() {
    this.messageEvent.emit(this.form.value);
  }
}
