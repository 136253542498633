import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeToLowercasePipe',
})
export class CapitalizeToLowercasePipePipe implements PipeTransform {
  transform(value: string): string {
    let modifiedValue = value.replace(/([A-Z])/g, ' $1').trim();
    let splitData = modifiedValue.split('To').splice(0, 1);
    return splitData + 'to you!!!';
  }
}
