<div [ngClass]="snackBarClass" class="custom-snackbar">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-mini-fab>
      <mat-icon
        style="color: #ff3e3e; font-size: 22px"
        *ngIf="data.snackBarType == 'error'"
        >warning</mat-icon
      >
      <mat-icon
        style="color: #52cb98; font-size: 22px"
        *ngIf="data.snackBarType == 'success'"
        >check_circle</mat-icon
      >
      <mat-icon
        style="color: #6ab9ff; font-size: 22px"
        *ngIf="data.snackBarType == 'info'"
        >info</mat-icon
      >
      <mat-icon
        style="color: #ffb545; font-size: 22px"
        *ngIf="data.snackBarType == 'warning'"
        >error</mat-icon
      >
    </button>
    <div style="padding: 5%"  fxLayout="row" fxLayoutAlign="center center">{{ data.message }}</div>
    <mat-icon class="close-icon" (click)="closeSnackbar()" matTooltip="close"
      >close</mat-icon
    >
  </div>
</div>
