import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';

@Component({
  selector: 'dcgrs-custom-tabs',
  templateUrl: './custom-tabs.component.html',
  styleUrls: ['./custom-tabs.component.scss'],
})
export class CustomTabsComponent {
  ids = ['jobId1', 'jobId2', 'jobId3'];
  tabs: string[] = [];
  selected = new FormControl(0);
  tabtitle: string = '';

  constructor(private _snackbar: SnackbarService) {}

  addTab(selectAfterAdding: boolean, name: string) {
    if (this.tabs.length <= 9) {
      if (this.tabtitle != '') {
        this.tabs.push(this.tabtitle);
      } else {
        this.tabs.push(name);
      }
      this.tabtitle = '';
      if (selectAfterAdding) {
        this.selected.setValue(this.tabs.length - 1);
      }
    } else {
      this._snackbar.openSnackBar_('Limit crossed!!!', 'error');
    }
  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
  }
}
