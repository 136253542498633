import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeadersConstants } from 'src/config/header.constants';
import { AppEndPoints } from 'src/config/end-points.constants';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(this.isTokenAvailable());

  constructor(
    public http: HttpClient,
    private _ls_service: LocalStorageService
  ) {}

  register(data: any) {
    let url = AppEndPoints.REGISTER;
    return this.http.post(url, data);
  }

  loginReq(data: any) {
    let url = AppEndPoints.LOGINREQUEST;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  acceptTandC(data: any) {
    let url = AppEndPoints.ACCCEPTTANDC;
    return this.http.post(url, data, {
      headers: HeadersConstants.getTokenHeader(),
    });
  }

  login(data: any) {
    let url = AppEndPoints.LOGIN;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  getProfile(userId: string) {
    let url = AppEndPoints.PROFILEDETAILS + 'userId=' + userId;
    return this.http.get(url, { headers: HeadersConstants.getTokenHeader() });
  }

  forgetPasswordemailLink(data: any) {
    let url = AppEndPoints.FORGOTPASSWORDLINK;
    return this.http.post(url, data, HeadersConstants.plainHeader);
  }

  updatePassword(data: any) {
    let url = AppEndPoints.UPDATEPASSWORD;
    return this.http.post(url, data, HeadersConstants.plainHeader);
  }

  logout() {
    let url =
      AppEndPoints.LOGOUT +
      'userId=' +
      this._ls_service.getItem('profile').userId;
    return this.http.post(
      url,
      {},
      { headers: HeadersConstants.getTokenHeader() }
    );
  }

  // this is used for auth guard.
  setLoggedin(val: boolean) {
    this.loggedIn.next(val);
  }

  isLoggedIn(): boolean {
    return this.loggedIn.value;
  }

  private isTokenAvailable(): boolean {
    return !!localStorage.getItem('token');
  }

  //this is used for token interceptor
  getToken(): string | null {
    const tokenString = localStorage.getItem('token');
    return tokenString ? JSON.parse(tokenString).token : null;
  }

  refreshToken(): Observable<any> {
    const tokenString = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    if (!tokenString) {
      return throwError(() => new Error('No token found'));
    }
    const refreshToken = JSON.parse(tokenString)?.ref;
    const authToken = JSON.parse(tokenString)?.token;
    const userRole = role ? JSON.parse(role)?.role : null;
    const userId = this._ls_service.getItem('profile').userId;

    if (!refreshToken) {
      return throwError(() => new Error('No refresh token found'));
    }
    if (!authToken) {
      return throwError(() => new Error('No authorization token found'));
    }
    if (!userRole) {
      return throwError(() => new Error('No user role found'));
    }

    const url =
      AppEndPoints.REFRESHTOKEN + 'userId=' + userId + '&ref=' + refreshToken;
    let headers = new HttpHeaders({
      role: userRole,
      authorization: authToken,
    });

    return this.http.post<any>(`${url}`, {}, { headers }).pipe(
      tap((response: any) => {
        if (response.status === 'success') {
          this._ls_service.setItem('loggedInUser', response.data.userDetails);
          localStorage.setItem(
            'token',
            JSON.stringify({
              token: response.data.tokens.token,
              ref: response.data.tokens.ref,
            })
          );
          this.setLoggedin(true);
        } else {
          this.logout();
        }
      }),
      catchError((error) => {
        // if (error.data.message === 'Invalid token!') {
          this.logout();
        // }
        return throwError(() => error);
      })
    );
  }
}
