import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEndPoints } from 'src/config/end-points.constants';
import { HeadersConstants } from 'src/config/header.constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(public http: HttpClient) {}

  getAllNotifications(userId: string) {
    let url =
      AppEndPoints.GET_ALL_NOTIFICATIONS +
      'userId=' +
      userId +
      '&page=1&count=10';
    return this.http.get(url, { headers: HeadersConstants.getTokenHeader() });
  }

  markNotificationAsRead(userId: string, data: any) {
    let url =
      AppEndPoints.MARK_NOTIFICATION_READ +
      'userId=' +
      userId;
    return this.http.post(url,data, { headers: HeadersConstants.getTokenHeader() });
  }

  markAllNotificationsRead(userId: string) {
    let url =
      AppEndPoints.MARK_NOTIFICATION_READ +
      'userId=' +
      userId;
    return this.http.post(url, { headers: HeadersConstants.getTokenHeader() });
  }
}
