import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { deleteNotification } from './../../animation';
import { MatDialog } from '@angular/material/dialog';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/_providers/_http/notifications.service';
import { AuthService } from 'src/_providers/_http/auth.service';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';
import { switchMap } from 'rxjs';
import { JobsService } from 'src/_providers/_http/jobs.service';

@Component({
  selector: 'dcps-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, AfterViewInit {
  // @Input() notifications: any[];
  colors: string[] = ['#153448', '#EE5253'];
  currentIndex: number = 0;
  backgroundColor: string = this.colors[this.currentIndex];
  notifications: any[] = [];
  notificationsDataLoading: boolean = false;

  constructor(
    private _route: Router,
    private _notification: NotificationsService,
    private _auth: AuthService,
    private _ls_service: LocalStorageService,
    private _jobs: JobsService
  ) {}

  ngOnInit(): void {
    this.notificationsDataLoading = true;
  }

  ngAfterViewInit(): void {
    this.getAllNotifications();
  }

  getAllNotifications() {
    this._notification
      .getAllNotifications(this._ls_service.getItem('profile').userId)
      .subscribe({
        next: (res: any) => {
          if (res.status == 'success') {
            this.notifications = res.data.notificationsData.notificationsData;
            this.notifications = this.notifications.slice(0, 2);
            this.getDetails();
          }
        },
        error: (err: any) => {
          this.notificationsDataLoading = false;
          // this._snackbar.openSnackBar_(err.error.data.message, 'error');
        },
      });
  }

  getDetails() {
    this.notifications.forEach((ele: any) => {
      let profileId: string | undefined;
      if (ele.name === 'JobAssignmentToRecruiter') {
        profileId = ele.params.recruitmentManagerId;
      } else if (ele.name === 'JobAssignmentToRecruitmentManager') {
        profileId = ele.params.salesManagerId;
      } else if (ele.name === 'CandidateAddedToJob') {
        profileId = ele.params.recruiterId;
      }

      if (profileId) {
        this._auth
          .getProfile(profileId)
          .pipe(
            switchMap((profileRes: any) =>
              this._jobs.getJobDetails(ele.params.jobId).pipe(
                switchMap((jobRes: any) => {
                  if (jobRes.status === 'success') {
                    const jobName = jobRes.data.jobDetails.jobName;
                    const jobNumber = jobRes.data.jobDetails.jobNo;
                    const profile = profileRes.data.profileDetails.profile;

                    let message = '';
                    if (
                      ele.name === 'JobAssignmentToRecruiter' ||
                      ele.name === 'JobAssignmentToRecruitmentManager'
                    ) {
                      message = `assigned job #${jobNumber} - ${jobName} for you`;
                    } else if (ele.name === 'CandidateAddedToJob') {
                      message = `submitted the profile for ${jobName}`;
                    }

                    Object.assign(ele, {
                      profile: profile,
                      message: message,
                    });
                  }
                  this.notificationsDataLoading = false;
                  return [];
                })
              )
            )
          )
          .subscribe({
            error: (err: any) => {
              console.error('Error occurred:', err);
            },
          });
      }
    });
  }

  getBackgroundColor(index: number) {
    this.backgroundColor = this.colors[index % 2];
    return this.backgroundColor;
  }

  openNotification(data: any) {
    if (data.name == 'JobAssignmentToRecruiter') {
      this._route.navigate(['/jobs/' + data.params.jobId]);
    } else if (data.name == 'JobAssignmentToRecruitmentManager') {
      this._route.navigate(['/job_details/' + data.params.jobId]);
    } else if (data.name == 'CandidateAddedToJob') {
      this._route.navigate([
        '/managerApproval/' +
          data.params.candidateId +
          '/' +
          data.params.recruiterId +
          '/' +
          data.params.jobId+'/applied',
      ]);
    }
  }

  all() {
    this._route.navigate(['/notifications']);
  }
}
