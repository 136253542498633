import { Component, Input } from '@angular/core';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';

@Component({
  selector: 'dcps-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() profileDetails: any;
  @Input() profile: boolean;
  profileInitials: string = ' ';
  profilePictureUrl: string | null;

  constructor(private _ls_service: LocalStorageService) {}

  ngOnInit(): void {
    if (this.profile) {
      this.profileInitials = this.getInitials(
        this.profileDetails.profile.firstName,
        this.profileDetails.profile.lastName
      );
      this.profileDetails = this._ls_service.getItem('profile');
      this.profilePictureUrl = this.profileDetails.imgUrl;
      if (!this.profilePictureUrl) {
        this.profileInitials = this.getInitials(
          this.profileDetails.profile.firstName,
          this.profileDetails.profile.lastName
        );
      }
    } else {
      setTimeout(() => {
        if (this.profileDetails) {
          if (typeof this.profileDetails.profile == 'undefined') {
            this.profileInitials = this.getInitials(' ', ' ');
          } else {
            this.profileInitials = this.getInitials(
              this.profileDetails.profile.firstName,
              this.profileDetails.profile.lastName
            );
          }
        }
      }, 1000);
    }
  }
  

  getInitials(firstName: string, lastName: string): string {
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  }
}
