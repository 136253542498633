import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  SwUpdate,
  VersionEvent,
  VersionReadyEvent,
} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'dcgrs-recruter_ui';
  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: Router,
    private swUpdate: SwUpdate
  ) {
    this.checkForUpdates();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([`(max-width: 1024px)`])
      .subscribe((result) => {
        if (result.matches) {
          this.route.navigate(['/unsupported']);
        }
        // else{
        //   this.route.navigate(['/dashboard']);
        // }
      });
  }

  checkForUpdates() {
    console.log(this.swUpdate.isEnabled)
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
        if (event.type === 'VERSION_READY') {
          const versionEvent = event as VersionReadyEvent;
          const updateMessage = `New version available! Current version: ${versionEvent.currentVersion}, New version: ${versionEvent.latestVersion}. Load New Version?`;

          // Notify the user or prompt them to reload the app
          if (confirm(updateMessage)) {
            window.location.reload();
          }
        }
      });
    }
  }
}
