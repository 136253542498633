import { Component } from '@angular/core';

@Component({
  selector: 'dcps-job-summary',
  templateUrl: './job-summary.component.html',
  styleUrls: ['./job-summary.component.scss'],
})
export class JobSummaryComponent {
  avatars = [
    {
      name: 'Avatar 1',
      imgUrl:
        'https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/004.webp',
      color: '#E91E63',
    },
    {
      name: 'Avatar 2',
      imgUrl:
        'https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?cs=srgb&dl=pexels-sulimansallehi-1704488.jpg&fm=jpg',
      color: '#2196F3',
    },
    { name: 'Avatar 3', imgUrl: null, color: '#4CAF50' },
    { name: 'Avatar 4', imgUrl: null, color: '#F44336' },
    { name: 'Avatar 5', imgUrl: null, color: '#FFEB3B' },
    { name: 'Avatar 6', imgUrl: null, color: '#00BCD4' },
  ];
}
