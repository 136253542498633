<ng-container>
  <mat-sidenav-container class="container" [ngStyle]="{ margin: margin }">
    <mat-sidenav
      #sidenav
      [mode]="mobileQuery.matches ? 'push' : 'side'"
      opened
      class="sidenav"
      [fixedInViewport]="true"
      [(opened)]="opened"
      [ngStyle]="{ width: sideNavWidth }"
      [@sidenavAnimation]="miniVersion ? 'open' : 'closed'"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="margin-top: 10%"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          style="margin-left: 10%"
        >
          <img class="logo" src="./../../../assets/dc_logo.png" />
          <div class="portal_name" *ngIf="!miniVersion">DCPS</div>
        </div>
      </div>
      <div
        class="side-nav-container"
        fxLayout="column"
        fxLayoutAlign="space-around center"
      >
        <div
          class="menu-container"
          [ngStyle]="miniVersion ? { width: '65%' } : { width: '100%' }"
        >
          <span *ngFor="let menu of menuList">
            <nav-list
              [name]="menu.name"
              [icon]="menu.icon"
              [link]="menu.link"
              [childMenu]="menu.subMenu"
              [action]="menu.action"
              [miniVersion]="miniVersion"
              [notificationsLength]="notifications.length"
              (messageEvent)="closeSideNavFromMenu($event)"
            ></nav-list>
          </span>
        </div>

        <div
          class="footer"
          fxLayout="column"
          fxLayoutAlign="space-around center"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            (click)="toProfile()"
            style="cursor: pointer"
            matTooltip="Profile"
          >
            <dcps-avatar
              *ngIf="!hasPicKey(profileDetails.profile)"
              [profileDetails]="profileDetails"
              [profile]="false"
              style="margin-left: 10%"
            ></dcps-avatar>
            <div
              *ngIf="hasPicKey(profileDetails.profile) && !this.miniVersion"
              class="profile-picture"
              [style.background-image]="backgroundImage"
            ></div>
            <div
              *ngIf="hasPicKey(profileDetails.profile) && this.miniVersion"
              class="profile-picture-mini-nav"
              [style.background-image]="backgroundImage"
            ></div>
            <div
              fxLayout="column"
              fxLayoutAlign="space-around center"
              style="margin-left: 10%"
              *ngIf="!miniVersion"
            >
              <div class="recr_name">
                {{ profileDetails.profile.firstName }}
              </div>
              <div
                class="designation"
                fxLayout="column"
                fxLayoutAlign="space-around center"
              >
                <span *ngIf="userRole.role == 'recruiter'">Talent Scout</span>
                <span *ngIf="userRole.role == 'recruitmentmanager'"
                  >Scout Manager</span
                >
                <span *ngIf="userRole.role == 'salesmanager'"
                  >Sales Manager</span
                >
              </div>
            </div>
          </div>
          <div>
            <div style="margin-top: 2%">
              <button
                mat-button
                matTooltip="logout"
                (click)="logout()"
                class="logout_"
                *ngIf="!miniVersion"
              >
                <mat-icon class="logout_icon">input</mat-icon
                ><span class="logoutAccount">Logout</span>
              </button>
              <button
                mat-icon-button
                *ngIf="miniVersion"
                class="logout_icon"
                style="font-size: 18px; margin-left: 1vw"
                matTooltip="logout"
                (click)="logout()"
              >
                <mat-icon>input</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content
      [ngStyle]="
        miniVersion ? { 'margin-left': '8%' } : { 'margin-left': '25%' }
      "
    >
      <div>
        <mat-icon
          style="font-size: 24px; cursor: pointer"
          (click)="decrease()"
          matTooltip="Collapse"
          *ngIf="!miniVersion"
          >arrow_left</mat-icon
        >
        <mat-icon
          style="font-size: 24px; cursor: pointer"
          (click)="increase()"
          matTooltip="Expand"
          *ngIf="miniVersion"
          >arrow_right</mat-icon
        >
      </div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
