import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';
import { AuthService } from 'src/_providers/_http/auth.service';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';
import { SnackbarService } from 'src/_providers/_http/snackbar.service';

@Component({
  selector: 'dcps-gauth-dailog',
  templateUrl: './gauth-dailog.component.html',
  styleUrls: ['./gauth-dailog.component.scss'],
})
export class GauthDailogComponent {
  // otpInput: any;
  @ViewChild(NgxOtpInputComponent) otpInput: NgxOtpInputComponent;
  showShake: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GauthDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _auth: AuthService,
    private _snackbar: SnackbarService,
    private _ls_service: LocalStorageService,
    private route: Router
  ) {}

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  gauthpassword: string;

  handeOtpChange(value: string[]): void {
  
  }

  handleFillEvent(value: string): void {
    this.gauthpassword = value;
    this.verify();
  }

  verify() {
    let postData = {
      email: this.data.email,
      password: this.data.password,
      otp: this.gauthpassword,
      ipaddress: this.data.ipAddress,
      latitude: this.data.latitude,
      longitude: this.data.longitude,
    };
    this._auth.login(postData).subscribe({
      next: (res: any) => {
        if (res.status == 'success') {
          this.onNoClick();
          this._ls_service.setItem('loggedInUser', res.data.userDetails);
          this._ls_service.setItem('token', res.data.tokens);
          this.getProfile(res.data.userDetails.userId);
          this._auth.setLoggedin(true);
          this._snackbar.openSnackBar_(res.data.message, 'success');
        }
      },
      error: (err: any) => {
        this.showShake = true;
        setTimeout(() => {
          this.showShake = false;
        }, 500);
        this._auth.setLoggedin(false);
        this._snackbar.openSnackBar_(err.error.data.message, 'error');
        this.clearOtpInput();
      },
    });
  }

  getProfile(userId: string) {
    this._auth.getProfile(userId).subscribe({
      next: (res: any) => {
        if (res.status == 'success') {
          this._ls_service.setItem('profile', res.data.profileDetails);
          if (this._ls_service.getItem('role').role == 'recruiter') {
            this.route.navigate(['/dashboard']);
          } else if (
            this._ls_service.getItem('role').role == 'recruitmentmanager' ||
            this._ls_service.getItem('role').role == 'salesmanager'
          ) {
            this.route.navigate(['/manager_dashboard']);
          }
        } else {
          this._snackbar.openSnackBar_('Somthing went wrong !!!', 'error');
        }
      },
      error: (err: any) => {
        if (err.error.status == 'failed') {
          this._snackbar.openSnackBar_(err.error.data.message, 'error');
        }
      },
    });
  }

  clearOtpInput() {
    if (this.otpInput) {
      this.otpInput.clear();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
