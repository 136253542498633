<span>
  <div
    class="main-component"
    [ngStyle]="{ 'margin-left': miniVersion ? '-10px' : '10%' }"
    [matTooltip]="name"
    [ngClass]="{ 'active-menu': isActive(link) }"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="space-between start"
      class="menu-item"
      [ngStyle]="{ height: currentScreenSize == 'Large' ? ' 10vh' : '6vh' }"
      currentScreenSize
      (click)="action_(childMenu)"
    >
      <div fxLayout="row" fxLayoutAlign="start center" class="menus">
        <div class="icon-container">
          <mat-icon>{{ icon }}</mat-icon>
        </div>
        <div *ngIf="!miniVersion" class="menu-name">
          {{ name }}
        </div>
        <div style="margin-left: 10% !important" *ngIf="badgeCount > 0">
          <span
            matBadge="{{ badgeCount }}"
            matBadgeColor="accent"
            *ngIf="showBadge && name == 'Notifications'"
          ></span>
        </div>
        <div style="margin-left: 10% !important">
          <span
            matBadge="{{ unreadCount }}"
            matBadgeColor="primary"
            *ngIf="unreadCount > 0 && name == 'Notifications'"
          ></span>
        </div>
      </div>
    </div>
  </div>
</span>
