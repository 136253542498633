import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { openDivAnimation } from './../../animation';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/_providers/_http/shared.service';
import { NotificationsService } from 'src/_providers/_http/notifications.service';
import { LocalStorageService } from 'src/_providers/_http/local-storage.service';

@Component({
  selector: 'nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
  animations: [openDivAnimation],
})
export class NavListComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() link: string;
  @Input() icon: string;
  @Input() childMenu: any;
  @Input() miniVersion: boolean;
  @Input() notificationsLength: any;
  @Input() action: boolean;
  @Output() messageEvent = new EventEmitter<boolean>();
  showSubmenu: boolean;
  typesOfShoes: string[] = ['sub-menu1', 'sub-menu2'];
  destroyed = new Subject<void>();
  currentScreenSize: string;

  showBadge: boolean = true;
  badgeCount: number = 0;
  unreadCount:number = 0;

  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  constructor(
    private router: Router,
    private _bottomSheet: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private _sharedService: SharedService,
    private _notification: NotificationsService,
    private _ls_service: LocalStorageService
  ) {
    inject(BreakpointObserver)
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
      });
      // this.getAllNotifications();
      this._sharedService.unread$.subscribe(val => this.unreadCount = val)
  }

  ngOnInit(): void {
    // this.getAllNotifications();
  }

  openBottomSheet(): void {
    this._bottomSheet.open(BottomSheetComponent, {
      panelClass: 'custom-dialog-top-sheet',
      hasBackdrop: true,
      width: '100%',
      position: { top: '0' },
      height: '80%',
      disableClose: true,
    });
  }

  // getAllNotifications() {
  //   this._notification
  //     .getAllNotifications(this._ls_service.getItem('profile').userId)
  //     .subscribe({
  //       next: (res: any) => {
  //         if (res.status == 'success') {
  //           let unread = res.data.notificationsData.notificationsData.filter(
  //             (notification: any) => notification.read === false
  //           );
  //           this.unreadCount = unread.length
  //         }
  //       },
  //     });
  // }

  action_(subMenu: any) {
    if (subMenu == null) {
      this.router.navigate([this.link]);
    } else {
      this.showSubmenu = !this.showSubmenu;
    }
    this.menuClicked();
  }

  isActive(link: string): boolean {
    return this.router.url === link;
  }

  menuClicked() {
    this.messageEvent.emit(true);
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['notificationsLength'] &&
      !changes['notificationsLength'].firstChange
    ) {
      this.badgeCount = this.notificationsLength;
      this._sharedService.updateCount(this.badgeCount);
      this.changeDetectorRef.detectChanges();
    }
  }
}
