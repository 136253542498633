<!-- <div class="demo-inline-calendar-card">
  <mat-calendar class="custom-calendar"
    [dateClass]="dateClass()"
    (selectedChange)="valueChanged($event)"
  ></mat-calendar>
</div> -->
<div class="container">
  <mat-calendar
  class="custom-calendar"
  [dateClass]="dateClass()"
  (selectedChange)="valueChanged($event)"
></mat-calendar>
</div>

