import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './_layout/home-layout/home-layout.component';
import { MainLayoutComponent } from './_layout/main-layout/main-layout.component';
import { PagenotfoundComponent } from 'src/_shared/_components/pagenotfound/pagenotfound.component';
import { AuthGuard } from './guards/auth.guard';
import { roleGuard } from './guards/role.guard';

const routes: Routes = [
  // Path for home layout
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('./../_modules/sign-in/sign-in.module').then(
            (m) => m.SignInModules
          ),
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./../_modules/registration/registration.module').then(
            (m) => m.RegistrationModules
          ),
      },
      {
        path: 'termsandconditions',
        loadChildren: () =>
          import('./../_modules/terms/terms.module').then(
            (m) => m.TermsModules
          ),
      },
      {
        path: 'authenticator',
        loadChildren: () =>
          import('./../_modules/authenticator/authenticator.module').then(
            (m) => m.AuthenticatorModules
          ),
      },
      {
        path: 'unsupported',
        loadChildren: () =>
          import('./../_modules/unsupported/unsupported.module').then(
            (m) => m.UnsupportedPageModules
          ),
      },
      {
        path: 'reset_password/:timeStamp/:token',
        loadChildren: () =>
          import('./../_modules/reset-password/reset-password.module').then(
            (m) => m.RestPasswordModules
          ),
      },
    ],
  },

  // Path for sidenav layout
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruiter'] },
        loadChildren: () =>
          import('./../_modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModules
          ),
      },
      {
        path: 'jobs',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruiter'] },
        loadChildren: () =>
          import('./../_modules/jobs-summary/jobs-summary.module').then(
            (m) => m.JobsSummaryModules
          ),
      },
      {
        path: 'jobs/:jobId',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruiter'] },
        loadChildren: () =>
          import('./../_modules/jobs/jobs.module').then((m) => m.JobsModules),
      },
      {
        path: 'resume',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruiter'] },
        loadChildren: () =>
          import('./../_modules/upload-resume/upload-resume.module').then(
            (m) => m.UploadResumeModules
          ),
      },
      {
        path: 'candidateprogress',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruiter'] },
        loadChildren: () =>
          import(
            './../_modules/candidate-progress/candidate-progress.module'
          ).then((m) => m.CandidateProgressModules),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/profile/profile.module').then(
            (m) => m.ProfileModules
          ),
      },
      {
        path: 'manager_dashboard',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruitmentmanager', 'salesmanager'] },
        loadChildren: () =>
          import(
            './../_modules/manager-dashboard/manager-dashboard.module'
          ).then((m) => m.ManagerDashboardModules),
      },
      {
        path: 'candidates',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruiter'] },
        loadChildren: () =>
          import('./../_modules/find-candidates/find-candidates.module').then(
            (m) => m.FindCandidatesModules
          ),
      },
      {
        path: 'manager_jobs',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruitmentmanager', 'salesmanager'] },
        loadChildren: () =>
          import('./../_modules/manager-jobs/manager-jobs.module').then(
            (m) => m.ManagerJobsModules
          ),
      },
      {
        path: 'job_details',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruitmentmanager', 'salesmanager'] },
        loadChildren: () =>
          import('./../_modules/job-details/job-details.module').then(
            (m) => m.ManageJobModules
          ),
      },
      {
        path: 'job_details/:id',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruitmentmanager', 'salesmanager'] },
        loadChildren: () =>
          import('./../_modules/job-details/job-details.module').then(
            (m) => m.ManageJobModules
          ),
      },
      {
        path: 'managerclients',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['salesmanager'] },
        loadChildren: () =>
          import('./../_modules/manager-clients/manager-clients.module').then(
            (m) => m.ManagerClientsModules
          ),
      },
      {
        path: 'managerApproval/:candidateId/:rec_id/:jobId/:status',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruitmentmanager'] },
        loadChildren: () =>
          import('./../_modules/manager-approval/manager-approval.module').then(
            (m) => m.ManagerApprovalModules
          ),
      },
      {
        path: 'notifications',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/notifications/notifications.module').then(
            (m) => m.NotificationsModules
          ),
      },
      {
        path: 'addcandidate/:jobId',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruiter'] },
        loadChildren: () =>
          import('./../_modules/add-candidate/add-candidate.module').then(
            (m) => m.AddCandidateModules
          ),
      },
      {
        path: 'Resume',
        canActivate: [AuthGuard, roleGuard],
        data: { roles: ['recruiter'] },
        loadChildren: () =>
          import('./../_modules/upload-resume/upload-resume.module').then(
            (m) => m.UploadResumeModules
          ),
      },
    ],
  },

  // Path for no layout

  { path: '**', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
