import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppEndPoints } from 'src/config/end-points.constants';
import { HeadersConstants } from 'src/config/header.constants';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private submitDocSource = new Subject<void>();
  submitFunction$ = this.submitDocSource.asObservable();

  private uploadedResumeData = new BehaviorSubject<any>(null);
  resumeData$ = this.uploadedResumeData.asObservable();

  private updatedPicSubject = new BehaviorSubject<boolean | null>(null);
  updatedPic$ = this.updatedPicSubject.asObservable();

  private selectedJobData = new BehaviorSubject<any>(null);
  jobData$ = this.selectedJobData.asObservable();

  private activenotificationCount = new BehaviorSubject<number>(0);
  notificationCount$ = this.activenotificationCount.asObservable();

  private candidateIdForm = new BehaviorSubject<number>(0);
  candidateIdForm$ = this.candidateIdForm.asObservable();

  private conditionsForm = new BehaviorSubject<number>(0);
  conditionsForm$ = this.conditionsForm.asObservable();

  private backToTabel = new BehaviorSubject<number>(0);
  backToTabel$ = this.backToTabel.asObservable();

  private unread = new BehaviorSubject<number>(0);
  unread$ = this.unread.asObservable();

  constructor(public http: HttpClient) {}

  callSubmitDoc() {
    this.submitDocSource.next();
  }

  resumeDataForm(res: any) {
    this.uploadedResumeData.next(res);
  }
  setValue(value: boolean): void {
    this.updatedPicSubject.next(value);
  }

  jobData(ele: any) {
    this.selectedJobData.next(ele);
  }

  updateCount(ele: any) {
    this.activenotificationCount.next(ele);
  }

  updateUnread(ele: any) {
    this.unread.next(ele);
  }

  candidateId(id: any) {
    this.candidateIdForm.next(id);
  }

  conditions(data: any) {
    this.conditionsForm.next(data);
  }

  jobTabel(ele: any) {
    this.backToTabel.next(ele);
  }

  getSettings() {
    let url = AppEndPoints.SETTINGSDATA;
    return this.http.get(url, { headers: HeadersConstants.getTokenHeader() });
  }
}
